import React, { Component, useRef, createRef } from 'react'
import { withFirebase } from '../firebase'
import Toasty from './toasty'
import CreatableSelect from 'react-select/creatable'
import { request } from 'https'
import EmailEditor from 'react-email-editor'

class Templates extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userLoggedInSpecified: false,
      prevAuthProp: null,
      toast: null,
      templates: {},
      options: [],
      chosen: null,
      keys: [],
    }
    this.inactiveUsersRef = null
    this.emailEditorRef = createRef()
    this.tmp = 0
    this.templatesRef = null
    this.keyRef = null
    this.saveTemplate = this.saveTemplate.bind(this)
    this.listenForTemplates = this.listenForTemplates.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.snapshotReceived = this.snapshotReceived.bind(this)
  }

  componentDidMount() {
    if (this.props.authUser !== undefined && this.props.authUser !== null) {
      this.setState({
        userLoggedInSpecified: true,
        prevAuthProp: this.props.authUser,
      })
      this.listenForTemplates()
      this.keyRef = this.props.firebase.database.ref(
        'apikeys/' + this.props.authUser.uid
      )
      this.keyRef.on('value', (snapshot) => {
        this.snapshotReceived(snapshot)
      })
    }
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {
    console.log(this.state.keys)
    if (
      this.props.authUser !== undefined &&
      this.props.authUser !== null &&
      !this.state.userLoggedInSpecified &&
      this.state.prevAuthProp !== this.props.authUser
    ) {
      this.setState({
        userLoggedInSpecified: true,
        prevAuthProp: this.props.authUser,
      })
      this.listenForTemplates()
      this.keyRef = this.props.firebase.database.ref(
        'apikeys/' + this.props.authUser.uid
      )
      this.keyRef.on('value', (snapshot) => {
        this.snapshotReceived(snapshot)
      })
    }
    if (
      JSON.stringify(this.state.templates) !==
        JSON.stringify(prevState.templates) ||
      Object.keys(this.state.templates).length !== this.state.options.length
    ) {
      console.log(this.state.templates)
      this.setState({
        options: Object.keys(this.state.templates).map((k) => ({
          label: this.state.templates[k].name,
          value: k,
        })),
      })
    }
  }

  snapshotReceived(fullSnapshot) {
    // console.log(fullSnapshot.val())
    const barr = []
    fullSnapshot.forEach((element) => {
      //   console.log(element.val())
      const oneKey = element.val()
      const o = {
        apitype: element.key,
        apikey: oneKey,
      }
      barr.push(o)
      this.setState({ keys: barr })
    })
  }

  saveTemplate() {
    if (this.state.chosen !== null) {
      console.log(this.state.chosen)
      this.emailEditorRef.current.editor.exportHtml((data) => {
        const { design, html } = data
        // console.log('exportHtml', html)
        // console.log(design)
        const o = {
          name: this.state.chosen.name,
          design: JSON.stringify(design),
          html: JSON.stringify(html),
        }
        this.props.firebase.database.ref('templates').update({
          [this.state.chosen.value]: o,
        })
        // but we must also sync this to prod & uat medme
        const bodydata = JSON.stringify({
          html: JSON.stringify(html)
        })

        const iuat = this.state.keys.findIndex(a => a.apitype === 'uat')

        const options = {
          hostname: 'us-central1-medme-uat.cloudfunctions.net',
          port: 443,
          path: `/manageTemplate?apikey=${this.state.keys[iuat].apikey}&templatekey=${this.state.chosen.value}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Content-Length': bodydata.length
          }
        }

        const req = request(options, res => {
          if (res.statusCode.toString() === '200') {
            this.setState({ toast: 'Template synced to UAT' })
            options.hostname = 'us-central1-medmeweb.cloudfunctions.net'
            options.path = `/manageTemplate?apikey=${this.state.keys[1 - iuat].apikey}&templatekey=${this.state.chosen.value}`
            const reqProd = request(options, res => {
              if (res.statusCode.toString() === '200') {
                this.setState({ toast: 'Template synced to Prod' })            
              } else {
                this.setState({ toast: 'Error in syncing template to Prod' })
              }
              res.on('data', d => {
                console.log(d)
              })
            })
    
            reqProd.on('error', error => {
              console.error(error)
              this.setState({ toast: 'Error in syncing template to Prod' })
            })
    
            reqProd.write(bodydata)
            reqProd.end()

          } else {
            this.setState({ toast: 'Error in syncing template to UAT' })
          }
          res.on('data', d => {
            console.log(d)
          })
        })

        req.on('error', error => {
          console.error(error)
          this.setState({ toast: 'Error in syncing template to UAT' })
        })

        req.write(bodydata)
        req.end()
      })
    }
  }

  listenForTemplates() {
    if (this.tmp === 0) {
      console.log('initializing tmp')
      this.tmp = 1
      this.templatesRef = this.props.firebase.database.ref('templates')
      this.templatesRef.on('child_added', (snapshot) => {
        // console.log(snapshot.val())
        // console.log(snapshot.key)
        this.setState((stt) => {
          const tmps = stt.templates || {}
          tmps[snapshot.key] = snapshot.val()
          console.log(tmps)
          return { templates: tmps }
        })
      })
      this.templatesRef.on('child_removed', (snapshot) => {
        this.setState((stt) => {
          const tmps = stt.templates || {}
          delete tmps[snapshot.key]
          return { templates: tmps }
        })
      })
    }
  }

  handleChange(newValue, actionMeta) {
    console.group('Value Changed')
    console.log(newValue)
    console.log(`action: ${actionMeta.action}`)
    console.groupEnd()
    if (actionMeta.action === 'create-option') {
      if (newValue.label.match(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/)) {
        this.setState({
          chosen: {
            name: newValue.label,
            value: newValue.label.replace(/ /g, '_').toLowerCase(),
          },
        })
      } else {
        this.setState({
          toast: 'Only letters, numbers and spaces allowed',
        })
      }
    } else if (actionMeta.action === 'clear') {
      this.setState({
        chosen: null,
      })
    } else if (actionMeta.action === 'select-option') {
      if (
        (this.state.templates[newValue.value] || {}).design !== null &&
        (this.state.templates[newValue.value] || {}).design !== undefined
      ) {
        const templateJson = JSON.parse(
          this.state.templates[newValue.value].design
        )
        this.emailEditorRef.current.editor.loadDesign(templateJson)
      }

      this.setState({
        chosen: {
          name: newValue.label,
          value: newValue.label.replace(/ /g, '_').toLowerCase(),
        },
      })
    }
  }
  handleInputChange(inputValue, actionMeta) {
    console.group('Input Changed')
    console.log(inputValue)
    console.log(`action: ${actionMeta.action}`)
    console.groupEnd()
  }

  render() {
    return (
      <>
        <div className="w-100 h-100 pl3 pr3 pt3 pb5 mb3">
          <div className="pb3">
            <button
              className={
                (this.state.chosen !== null
                  ? 'bg-medmeblue-light grow pointer'
                  : 'bg-near-white gray strike') +
                ' bn br3 ph3 pv2 mr2 fw2 mid-gray'
              }
              onClick={this.saveTemplate}
            >
              Save Template
            </button>
          </div>
          <CreatableSelect
            isClearable
            onChange={this.handleChange}
            onInputChange={this.handleInputChange}
            options={this.state.options}
          />
          <div className={`${this.state.chosen === null ? 'dn' : 'db'}`}>
            <EmailEditor ref={this.emailEditorRef} onLoad={this.onLoad} />
          </div>
        </div>
        <Toasty message={this.state.toast} />
      </>
    )
  }
}

export default withFirebase(Templates)
