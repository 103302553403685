import React, { Component } from 'react'
import { withFirebase } from '../firebase'
import { Link } from 'react-router-dom'

class Header extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="w-100 pa3 bg-medmegreen h-100 grid gafc items-center">
        <Link className="no-outline gcs1 gce2 pointer link" to="/">
          <img className="relative w2 h2 di nt2 nb2 mr2" src="logo.png"></img>
          <div className="f3 white tracked-mega di">MedMe Console</div>
        </Link>
        <div className="gcs2 gce3 js-right">
          <SignInForm authUser={this.props.authUser} />
        </div>
      </div>
    )
  }
}

const INITIAL_STATE = { email: '', password: '', error: null }

class SignInFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
    this.onSubmit = this.onSubmit.bind(this)
    this.signOut = this.signOut.bind(this)
  }

  signOut() {
    this.props.firebase.signOut()
  }

  onSubmit(event) {
    event.preventDefault()

    this.props.firebase
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then(authUser => {
        this.setState({ ...INITIAL_STATE })
      })
      .catch(error => {
        this.setState({ error })
      })
  }

  render() {
    const isInvalid = this.state.email === '' || this.state.password === ''

    return (
      <div className="flex items-center">
        {this.props.authUser === undefined ? null : this.props.authUser !==
          null ? (
          <button
            className="bg-medmeblue-light bn grow br3 ph3 pv2 fw2 mid-gray"
            onClick={this.signOut}
          >
            Sign Out
          </button>
        ) : (
          <>
            <form
              id="signInForm"
              className="flex items-center white"
              onSubmit={this.onSubmit}
            >
              <input
                className="outline-0 bn bg-transparent white fw2 medme-form-input"
                type="email"
                placeholder="Email"
                aria-label="Email"
                onChange={e => {
                  this.setState({ email: e.target.value })
                }}
              />
              <input
                className="outline-0 bn bg-transparent white fw2 medme-form-input"
                type="password"
                placeholder="Password"
                aria-label="Password"
                onChange={e => {
                  this.setState({ password: e.target.value })
                }}
              />
            </form>
            <button
              className={
                isInvalid
                  ? 'bg-medmeblue-light bn br3 ph3 pv2 fw2 light-gray'
                  : 'bg-medmeblue-light bn grow br3 ph3 pv2 fw2 mid-gray'
              }
              disabled={isInvalid}
              type="submit"
              form="signInForm"
              value="Submit"
            >
              Sign In
            </button>
          </>
        )}
      </div>
    )
  }
}

const SignInForm = withFirebase(SignInFormBase)

export default Header
export { SignInForm }
