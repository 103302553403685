import React, { Component, Fragment } from 'react'
import { withFirebase } from '../firebase'
import Toasty from './toasty'
import Select from 'react-select'
import { request } from 'https'
import { parse } from 'path'

class Events extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userLoggedInSpecified: false,
      prevAuthProp: null,
      keys: [],
      toast: null,
      isClearable: true,
      isSearchable: true,
      practices: [],
      practiceId: '',
      taskId: '',
      events: [],
    }
    this.keyRef = null
    this.mode = 0
    this.snapshotReceived = this.snapshotReceived.bind(this)
    this.fetchPractices = this.fetchPractices.bind(this)
    this.fetchEvents = this.fetchEvents.bind(this)
    this.selectOption = this.selectOption.bind(this)
  }

  componentDidMount() {
    if (this.props.authUser !== undefined && this.props.authUser !== null) {
      this.setState({
        userLoggedInSpecified: true,
        prevAuthProp: this.props.authUser,
      })
      this.keyRef = this.props.firebase.database.ref(
        'apikeys/' + this.props.authUser.uid
      )
      this.keyRef.on('value', (snapshot) => {
        this.snapshotReceived(snapshot)
      })
    }
  }

  componentWillUnmount() {
    if (this.keyRef !== null) {
      this.keyRef.off()
    }
  }

  componentDidUpdate() {
    if (
      this.props.authUser !== undefined &&
      this.props.authUser !== null &&
      !this.state.userLoggedInSpecified &&
      this.state.prevAuthProp !== this.props.authUser
    ) {
      this.setState({
        userLoggedInSpecified: true,
        prevAuthProp: this.props.authUser,
      })
      this.keyRef = this.props.firebase.database.ref(
        'apikeys/' + this.props.authUser.uid
      )
      this.keyRef.on('value', (snapshot) => {
        this.snapshotReceived(snapshot)
      })
    }
  }

  snapshotReceived(fullSnapshot) {
    // console.log(fullSnapshot.val())
    const barr = []
    fullSnapshot.forEach((element) => {
        // console.log(element.val())
      const oneKey = element.val()
      const o = {
        apitype: element.key,
        apikey: oneKey,
      }
      barr.push(o)
      this.setState({ keys: barr })
    })
    if (this.state.practices.length === 0) {
      // console.log(barr)
      setTimeout(() => {
        this.fetchPractices()
      }, 100)
    }
  }

  fetchPractices() {
    const uatmode = this.state.keys[this.mode].apitype.toLowerCase() === 'uat'

    const options = {
      hostname: uatmode
        ? 'us-central1-medme-uat.cloudfunctions.net'
        : 'us-central1-medmeweb.cloudfunctions.net',
      port: 443,
      path: `/practiceList?apikey=${this.state.keys[this.mode].apikey}`,
      method: 'GET',
    }
    const req = request(options, (res) => {
      if (res.statusCode.toString() === '200') {
        this.setState({ toast: 'Practice list fetched' })
        // console.log(res)
      } else {
        this.setState({ toast: 'Error in fetching practice list' })
        console.log('error in fetching practices')
        // console.log(res)
      }
      res.setEncoding('utf8')
      let rawData = ''
      res.on('data', (chunk) => {
        rawData += chunk
      })
      res.on('end', () => {
        try {
          const parsedData = JSON.parse(rawData)
          console.log(parsedData)
          const dataArr = Object.keys(parsedData).map((k) => ({
            label: k,
            value: {
              practiceId: k,
              medmeId: parsedData[k].user,
            },
          }))
          this.setState({ practices: dataArr })
        } catch (e) {
          console.error(e.message)
        }
      })
    })

    req.on('error', (error) => {
      console.error(error)
      this.setState({ toast: 'Error in fetching practice list' })
    })

    req.end()
  }

  fetchEvents() {
    const uatmode = this.state.keys[this.mode].apitype.toLowerCase() === 'uat'

    const options = {
      hostname: uatmode
        ? 'us-central1-medme-uat.cloudfunctions.net'
        : 'us-central1-medmeweb.cloudfunctions.net',
      port: 443,
      path: `/appointmentEvents?apikey=${
        this.state.keys[this.mode].apikey
      }&practiceid=${this.state.practiceId}&appointmentid=${this.state.taskId}`,
      method: 'GET',
    }
    const req = request(options, (res) => {
      if (res.statusCode.toString() === '200') {
        this.setState({ toast: 'Events fetched' })
        console.log(res)
      } else {
        this.setState({ toast: 'Error in fetching events' })
      }
      res.setEncoding('utf8')
      let rawData = ''
      res.on('data', (chunk) => {
        rawData += chunk
      })
      res.on('end', () => {
        try {
          const parsedData = JSON.parse(rawData)
          console.log(parsedData)
          const dataArr = Object.keys(parsedData).map((k) => ({
            time: parsedData[k].time,
            event: parsedData[k].event,
            payload: parsedData[k].payload,
          }))
          function doSort(a, b) {
            return a < b ? -1 : 1
          }
          dataArr.sort(doSort)
          this.setState({ events: dataArr })
        } catch (e) {
          console.error(e.message)
          this.setState({ toast: 'Error in fetching events, no such event?' })
        }
      })
    })

    req.on('error', (error) => {
      console.error(error)
      this.setState({ toast: 'Error in fetching events' })
    })

    req.end()
  }

  selectOption(option) {
    console.log(option)
    if (option === null) {
      // cleared
      this.setState({ practiceId: '' })
    } else {
      this.setState({ practiceId: option.value.medmeId })
    }
  }

  render() {
    const { isClearable, isSearchable, practices } = this.state
    return (
      <>
        <div className="w-100 h-100 pa3">
          <div className="pb3">
            <button
              className={
                (this.state.keys.length !== 0
                  ? 'bg-medmeblue-light grow pointer'
                  : 'bg-near-white gray strike') +
                ' bn br3 ph3 pv2 mr2 fw2 mid-gray'
              }
              onClick={() => {
                this.setState({ practices: [] })
                this.mode = 1 - this.mode
                this.fetchPractices()
              }}
            >
              {this.state.keys.length === 0
                ? 'Loading modes'
                : `Mode: ${
                    this.state.keys[this.mode].apitype.toLowerCase() === 'uat'
                      ? 'UAT'
                      : 'Prod'
                  }`}
            </button>
          </div>
          <Select
            className="basic-single"
            classNamePrefix="select"
            isLoading={practices.length === 0}
            isClearable={isClearable}
            isSearchable={isSearchable}
            name="color"
            options={practices}
            onChange={this.selectOption}
            noOptionsMessage="Fetching list of practice ids, please wait"
            placeholder="Select Practice id"
          />
          <br />
          <input
            className="w-100 medmeblue-dark no-outline br2 b--light-gray pl2 pv2 ba"
            type="text"
            value={this.state.taskId}
            onChange={(e) => {
              this.setState({ taskId: e.target.value.toString() })
            }}
            placeholder="Task ID"
          />
          <div className="pv3">
            <button
              className={
                (this.state.practiceId !== '' && this.state.taskId !== ''
                  ? 'bg-medmeblue-light grow pointer'
                  : 'bg-near-white gray strike') +
                ' bn br3 ph3 pv2 mr2 fw2 mid-gray'
              }
              onClick={() => {
                if (this.state.practiceId !== '' && this.state.taskId !== '') {
                  this.fetchEvents()
                }
              }}
            >
              Fetch events
            </button>
          </div>
          {this.state.events === [] ? null : (
            <div className="w-100 grid bg-medmeblue-light">
              <div className="gcs1 gce2 pa2">Timestamp</div>
              <div className="gcs2 gce3 pa2">Event</div>
              <div className="gcs3 gce4 pa2">Payload</div>
              {this.state.events.map((event, i) => (
                <Fragment key={i}>
                  <div
                    className={
                      (i > 0 ? 'bt b--light-silver ' : '') +
                      (i % 2 === 1 ? 'bg-near-white' : 'bg-white') +
                      ' gcs1 gce2 pa2'
                    }
                  >
                    {event.time}
                  </div>
                  <div
                    className={
                      (i > 0 ? 'bt b--light-silver ' : '') +
                      (i % 2 === 1 ? 'bg-near-white' : 'bg-white') +
                      ' gcs2 gce3 pa2'
                    }
                  >
                    {event.event}
                  </div>
                  <div
                    className={
                      (i > 0 ? 'bt b--light-silver ' : '') +
                      (i % 2 === 1 ? 'bg-near-white' : 'bg-white') +
                      ' gcs3 gce4 pa2 pre'
                    }
                  >
                    {event.payload === undefined
                      ? ''
                      : JSON.stringify(event.payload, null, ' ')}
                  </div>
                </Fragment>
              ))}
            </div>
          )}
        </div>
        <Toasty message={this.state.toast} />
      </>
    )
  }
}

export default withFirebase(Events)
