import React, { Component } from 'react'
import { withFirebase } from '../firebase'
import Toasty from './toasty'
import { request } from 'https'

class Admin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userLoggedInSpecified: false,
      prevAuthProp: null,
      users: [],
      toast: null,
      inactiveusers: [],
      isfiltered: true,
      searchfor: ''
    }
    this.admin = ''
    this.origusers = ''
    this.usersRef = null
    this.inactiveUsersRef = null
    this.snapshotReceived = this.snapshotReceived.bind(this)
    this.handleDataCheck = this.handleDataCheck.bind(this)
    this.handleDataChange = this.handleDataChange.bind(this)
    this.resetChanges = this.resetChanges.bind(this)
    this.activateChanges = this.activateChanges.bind(this)
    this.expandUser = this.expandUser.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.selectNone = this.selectNone.bind(this)
    this.toggleIsActive = this.toggleIsActive.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
  }

  componentDidMount() {
    if (this.props.authUser !== undefined && this.props.authUser !== null) {
      this.setState({
        userLoggedInSpecified: true,
        prevAuthProp: this.props.authUser
      })
      if (this.usersRef === null) {
        this.usersRef = this.props.firebase.database.ref('users')
        this.usersRef.on('child_added', snapshot => {
          this.snapshotReceived(snapshot)
        })
        this.usersRef.on('child_changed', snapshot => {
          this.snapshotReceived(snapshot)
        })
        this.inactiveUsersRef = this.props.firebase.database.ref('inactiveusers')
        this.inactiveUsersRef.on('value', snapshot => {
          if (snapshot.val() !== null) {
            this.setState({inactiveusers: snapshot.val().arr})
          }
        })
      }
      this.props.firebase.database.ref('admin').once('value', snapshot => {
        this.admin = snapshot.val()
      })
    }
  }

  componentWillUnmount() {
    if (this.usersRef !== null) {
      this.usersRef.off()
    }
    if (this.inactiveUsersRef !== null) {
      this.inactiveUsersRef.off()
    }
  }

  componentDidUpdate() {
    if (
      this.props.authUser !== undefined &&
      this.props.authUser !== null &&
      !this.state.userLoggedInSpecified &&
      this.state.prevAuthProp !== this.props.authUser
    ) {
      this.setState({
        userLoggedInSpecified: true,
        prevAuthProp: this.props.authUser
      })
      if (this.usersRef === null) {
        this.usersRef = this.props.firebase.database.ref('users')
        this.usersRef.on('child_added', snapshot => {
          this.snapshotReceived(snapshot)
        })
        this.usersRef.on('child_changed', snapshot => {
          this.snapshotReceived(snapshot)
        })
        this.inactiveUsersRef = this.props.firebase.database.ref('inactiveusers')
        this.inactiveUsersRef.on('value', snapshot => {
          if (snapshot.val() !== null) {
            this.setState({inactiveusers: snapshot.val().arr})
          }
        })
      }
      this.props.firebase.database.ref('admin').once('value', snapshot => {
        this.admin = snapshot.val()
      })
    }
  }

  resetChanges() {
    this.setState({
      users: JSON.parse(this.origusers)
    })
  }

  activateChanges() {
    const uarr = this.state.users.filter(a => a.changed === true)

    if (uarr.length === 0) {
      this.setState({ toast: 'No practice changes to process' })
    } else {
      const origusers = JSON.parse(this.origusers)

      let outUsers = []
      for (let index = 0; index < uarr.length; index++) {
        const element = uarr[index]
        const orig = origusers.filter(a => uarr[index].uid === a.uid)[0]
        let o = {}
        for (let idx = 0; idx < element.dataarr.length; idx++) {
          const onePair = element.dataarr[idx]
          if (
            orig[onePair.ukey].toString() !== onePair.uvalue.toString() ||
            onePair.ukey === 'uid'
          ) {
            o[onePair.ukey] = onePair.uvalue
          }
          if (idx === element.dataarr.length - 1) {
            outUsers.push(o)
          }
        }
        if (index === uarr.length - 1) {
          const data = JSON.stringify({
            users: outUsers
          })

          const options = {
            hostname: 'us-central1-medmeweb.cloudfunctions.net',
            port: 443,
            path: '/consoleUsersChange?admin=' + this.admin,
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Content-Length': data.length
            }
          }

          const req = request(options, res => {
            if (res.statusCode.toString() === '200') {
              this.setState({ toast: 'Practice changes synced' })
            } else {
              this.setState({ toast: 'Error in syncing practice changes' })
            }
            res.on('data', d => {
              console.log(d)
            })
          })

          req.on('error', error => {
            console.error(error)
            this.setState({ toast: 'Error in syncing practice changes' })
          })

          req.write(data)
          req.end()
        }
      }
    }
  }

  handleCheck(event) {
    const usrs = this.state.users
    const indx = usrs.findIndex(a => a.uid === event.target.name)
    usrs[indx].selected = !usrs[indx].selected
    this.setState({
      users: usrs
    })
    
    
  }

  handleDataCheck(event) {
    const uid = event.target.name.split('|')[0]
    const key = event.target.name.split('|')[1]
    const usrs = this.state.users
    const indx = usrs.findIndex(a => a.uid === uid)
    // console.log(event)
    usrs[indx][key] = !usrs[indx][key]
    const dtaindx = usrs[indx].dataarr.findIndex(a => a.ukey === key)
    usrs[indx].dataarr[dtaindx].uvalue = !usrs[indx].dataarr[dtaindx].uvalue
    usrs[indx].changed = true
    this.setState({
      users: usrs
    })
  }

  handleDataChange(event) {
    const uid = event.target.name.split('|')[0]
    const key = event.target.name.split('|')[1]

    const usrs = this.state.users
    const indx = usrs.findIndex(a => a.uid === uid)
    usrs[indx][key] = event.target.value
    usrs[indx].changed = true
    const dtaindx = usrs[indx].dataarr.findIndex(a => a.ukey === key)
    usrs[indx].dataarr[dtaindx].uvalue = event.target.value
    this.setState({
      users: usrs
    })
  }

  expandUser(theUid) {
    const usrs = this.state.users

    for (let index = 0; index < usrs.length; index++) {
      usrs[index].expanded =
        usrs[index].uid === theUid ? !usrs[index].expanded : false
      if (index === usrs.length - 1) {
        this.setState({
          users: usrs
        })
      }
    }
  }

  handleSelect(event) {
    const uid = event.target.name
    const usrs = this.state.users
    const indx = usrs.findIndex(a => a.uid === uid)
    usrs[indx].selected = !usrs[indx].selected
    this.setState({
      users: usrs
    })
    this.props.doFilterUsers(usrs.filter(a => a.selected).map(a => a.uid))
  }

  selectNone() {
    this.props.doFilterUsers([])
    const usrs = this.state.users
    for (let index = 0; index < usrs.length; index++) {
      const element = usrs[index]
      element.selected = false
      if (index === (usrs.length - 1)) {
        this.setState({
          users: usrs
        })
      }
    }
  }

  toggleIsActive() {
    const usrs = this.state.users
    const inactv = this.state.inactiveusers
    for (let index = 0; index < usrs.length; index++) {
      const element = usrs[index]
      if (element.selected) {
        const ipos = inactv.findIndex(a => a === element.uid)
        if (ipos >= 0) {
          // was inactive, make active
          inactv.splice(ipos, 1)
        } else {
          // was active, make inactive
          inactv.push(element.uid)
        }
        element.selected = false
      }
      if (index === (usrs.length - 1)) {
        if (this.inactiveUsersRef !== null) {
          this.inactiveUsersRef.update({arr: inactv})
        }
        this.setState({
          users: usrs
        })
      }
    }
  }


  snapshotReceived(fullSnapshot) {
    function doSort(a, b) {
      return a.short.toUpperCase() < b.short.toUpperCase() ? -1 : 1
    }

    const usrs = this.state.users
    const outBanner = fullSnapshot.val()
    if (outBanner.console !== this.props.authUser.uid && this.props.authUser.uid !== 'gKnjQPXp4pf0920MDWT7I5vMGt63') {
      return
    }
    outBanner['uid'] = fullSnapshot.key
    const fullSnapshotKeys = Object.keys(outBanner)
    outBanner['dataarr'] = []
    fullSnapshotKeys.forEach(oneKey => {
      outBanner['dataarr'].push({
        ukey: oneKey,
        uvalue: outBanner[oneKey],
        isBoolean: outBanner[oneKey] === true || outBanner[oneKey] === false,
        canEdit: oneKey !== 'uid' && oneKey !== 'doctors'
      })
    })

    outBanner['expanded'] = false
    outBanner['changed'] = false

    const pat_tnc_idx = outBanner['dataarr'].findIndex(d => d.ukey === 'patienttnc')

    if (pat_tnc_idx === -1) {
      // not yet defined
      outBanner['dataarr'].push({
        ukey: 'patienttnc',
        uvalue: '',
        isBoolean: false,
        canEdit: true
      })
      outBanner['patienttnc'] = ''
    }

    const idx = usrs.findIndex(a => a.uid === fullSnapshot.key)

    if (idx !== -1) {
      usrs[idx] = outBanner
    } else {
      usrs.push(outBanner)
    }

    usrs.sort(doSort)
    this.setState({ users: usrs })
    this.origusers = JSON.stringify(usrs)
  }

  render() {
    return (
      <>
        <div className="w-100 h-100 pl3 pr3 pt3 pb5 mb3">
          <div className="pb3">
            <button
              className={
                (this.state.users.filter(a => a.changed).length === 0
                  ? 'bg-near-white gray strike'
                  : 'bg-medmeblue-light grow pointer') +
                ' bn br3 ph3 pv2 mr2 fw2 mid-gray'
              }
              onClick={
                this.state.users.filter(a => a.changed).length === 0
                  ? null
                  : this.resetChanges
              }
            >
              Reset Changes
            </button>
            <button
              className={
                (this.state.users.filter(a => a.changed).length === 0
                  ? 'bg-near-white gray strike'
                  : 'bg-medmeblue-light grow pointer') +
                ' bn br3 ph3 pv2 mr2 fw2 mid-gray'
              }
              onClick={
                this.state.users.filter(a => a.changed).length === 0
                  ? null
                  : this.activateChanges
              }
            >
              Sync Changes to MedMe
            </button>
            <button
              className={
                'bg-medmeblue-light grow pointer' +
                ' bn br3 ph3 pv2 mr2 fw2 mid-gray'
              }
              onClick={
                () => {this.setState({isfiltered: !this.state.isfiltered}) }
              }
            >
              {this.state.isfiltered ? 'Show All' : 'Show Active'}
            </button>
            <button
              className={
                (this.state.users.filter(a => a.selected).length === 0
                  ? 'bg-near-white gray strike'
                  : 'bg-medmeblue-light grow pointer') +
                ' bn br3 ph3 pv2 mr2 fw2 mid-gray'
              }
              onClick={
                this.state.users.filter(a => a.selected).length === 0
                  ? null
                  : this.selectNone
              }
            >
              Select None
            </button>
            <button
              className={
                (this.state.users.filter(a => a.selected).length === 0
                  ? 'bg-near-white gray strike'
                  : 'bg-medmeblue-light grow pointer') +
                ' bn br3 ph3 pv2 mr2 fw2 mid-gray'
              }
              onClick={
                this.state.users.filter(a => a.selected).length === 0
                  ? null
                  : this.toggleIsActive
              }
            >
              Toggle Inactive
            </button>
            <div className="fr w5">
              <input
                className="w-75 medmeblue-dark no-outline br2 b--light-gray pl2 pv2 ba"
                type="text"
                value={this.state.searchfor}
                onChange={(e) => {
                  if (this.state.users.filter(a => a.selected).length > 0) {
                    this.selectNone()
                  }
                  this.setState({searchfor: e.target.value.toString()})
                }}
              />
              <button
              className={
                (this.state.searchfor.length === 0
                  ? 'bg-near-white gray strike'
                  : 'bg-medmeblue-light grow pointer') +
                ' bn br3 ph3 pv2 mh2 fw2 mid-gray fr'
              }
              onClick={
                () => {this.setState({searchfor: ''})}
              }
            >
              X
            </button>
            </div>
          </div>
          <div className="w-100 grid user-grid jc-start bg-medmeblue-light">
            <div className="gcs1 gce2 grs1 gre2 pa2">X</div>
            <div className="gcs2 gce3 grs1 gre2 pa2">Short</div>
            <div className="gcs3 gce4 grs1 gre2 pa2">Detail</div>
            <div className="gcs4 gce5 grs1 gre2 pa2">Email</div>
            <div className="gcs5 gce6 grs1 gre2 pa2">&#9742;</div>
          </div>

          {this.state.users
          .filter(f => (f.uid.toUpperCase().search(this.state.searchfor.toUpperCase()) !== -1 ||
                        f.short.toUpperCase().search(this.state.searchfor.toUpperCase()) !== -1 ||
                        f.email.toUpperCase().search(this.state.searchfor.toUpperCase()) !== -1) ||
                        f.details.toUpperCase().search(this.state.searchfor.toUpperCase()) !== -1
                        || this.state.searchfor === '')
          .filter(f => (!this.state.inactiveusers.includes(f.uid) || !this.state.isfiltered)).map((oneUser, i) => {
            return (
              <div
                className={
                  (i > 0 ? 'bt b--light-silver ' : '') +
                  (i % 2 === 1 ? 'bg-near-white' : 'bg-white') +
                  ' w-100 grid user-grid jc-start'
                }
                key={i}
              >
                <div
                  className={
                    (oneUser.changed ? 'i medmeblue-dark' : '') +
                    ' gcs1 gce2 grs1 gre2 pa2 pointer dim'
                  }
                >
                  <input
                    type="checkbox"
                    value={oneUser.selected}
                    
                    onChange={this.handleSelect}
                    name={oneUser.uid}
                  />
                  {/* checked={oneUser.selected} */}
                </div>
                <div
                  className={
                    (oneUser.changed ? 'i medmeblue-dark' : '') +
                    ' gcs2 gce3 grs1 gre2 pa2 pointer dim'
                  }
                  onClick={e => this.expandUser(oneUser.uid)}
                >
                  {oneUser.short}
                </div>
                <div
                  className="gcs3 gce4 grs1 gre2 pa2 flex-wrap pointer dim"
                  onClick={e => this.expandUser(oneUser.uid)}
                >
                  {oneUser.details}
                </div>
                <div className="gcs4 gce5 grs1 gre2 pa2 pointer dim">
                  <a className="black link flex-wrap word-break-all" href={'mailto:' + oneUser.email}>
                    {oneUser.email}
                  </a>
                </div>
                <div className="gcs5 gce6 grs1 gre2 pa2 pointer dim">
                  <a
                    className="medmegreen-dark link"
                    href={'tel:' + oneUser.phone}
                  >
                    &#9742;
                  </a>
                </div>
                {oneUser.expanded ? (
                  <div className="gcs1 gce6 grs2 gre3 pt2 pb2 pl3 pr3">
                    {oneUser.dataarr.map((oneSet, j) => {
                      return (
                        <div
                          className={
                            (j > 0 ? 'bt b--light-silver ' : '') +
                            (j % 2 === 1 ? 'bg-near-white' : 'bg-white') +
                            ' w-100 grid jc-start user-data-grid'
                          }
                          key={100000 + j}
                        >
                          <div className="gcs1 gce2 grs1 gre2 pa1">
                            {oneSet.ukey}
                          </div>
                          {oneSet.canEdit ? (
                            oneSet.isBoolean ? (
                              <div>
                                <input
                                  type="checkbox"
                                  checked={oneSet.uvalue}
                                  onChange={this.handleDataCheck}
                                  name={oneUser.uid + '|' + oneSet.ukey}
                                />
                              </div>
                            ) : (
                              <div className="w-100">
                                <input
                                  className="w-100"
                                  type="text"
                                  value={oneSet.uvalue}
                                  onChange={this.handleDataChange}
                                  name={oneUser.uid + '|' + oneSet.ukey}
                                />
                              </div>
                            )
                          ) : (
                            <div className="gcs2 gce3 grs1 gre2 i">
                              {oneSet.uvalue.toString()}
                            </div>
                          )}
                        </div>
                      )
                    })}
                  </div>
                ) : null}
              </div>
            )
          })}

          <div className="w-100 h2"></div>
        </div>
        <Toasty message={this.state.toast} />
      </>
    )
  }
}

export default withFirebase(Admin)
