import React, { Component } from 'react'

class Toasty extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      displaymessage: null,
      addclass: '',
      prevmessage: null
    }
    this.props = {
      message: null
    }
    this.proppy = this.proppy.bind(this)
  }

  componentDidUpdate() {
    if (this.props.message !== null && this.state.prevmessage !== this.props.message ) {
      this.proppy()
    }
  }

  proppy() {
    this.setState({ displaymessage: this.props.message, prevmessage: this.props.message })
    setTimeout(() => {
      this.setState({ addclass: 'show' })
    }, 10)
    setTimeout(() => {
      this.setState({ addclass: '' })
      setTimeout(() => {
        this.setState({ displaymessage: null })
      }, 3000)
    }, 5000)
  }

  render() {
    return this.state.displaymessage === null ? null : (
      <div
        className={this.state.addclass + ' toasty fixed bottom-0 vw-100 left-0'}
      >
        <div className="dt pa3 center bg-light-red bg-animate bn br3">
          {this.state.displaymessage}
        </div>
      </div>
    )
  }
}

export default Toasty
