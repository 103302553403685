import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'

import Header from './header'
import Navigation from './navigation'
import Home from './home'
import Landing from './landing'
import Api from './api'
import Account from './account'
import Banners from './banners'
import Stats from './stats'
import Admin from './admin'
import MedicalAid from './medicalaid'
import { withFirebase } from '../firebase'

import * as V from 'victory'

import * as ROUTES from '../constants/routes'
import Callbacks from './callbacks'
import Templates from './templates'
import Events from './events'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      authUser: undefined,
      usersFiltered: [],
    }
    this.filterUsers = this.filterUsers.bind(this)
  }

  componentDidMount() {
    this.props.firebase.auth.onAuthStateChanged((authUser) => {
      authUser ? this.setState({ authUser }) : this.setState({ authUser: null })
    })
    console.log(this.props)
  }

  filterUsers(usersFiltered) {
    console.log(usersFiltered)
    this.setState({ usersFiltered: usersFiltered })
  }

  render() {
    return (
      <div className="roboto">
        <Router>
          <div className="grid vw-100 vh-100 fixed top-0 left-0 gtr-header">
            <div className="grs1 gre2 gcs1 gce2 grid">
              <Header authUser={this.state.authUser} />
            </div>
            <div className="grs2 gre3 gcs1 gce2 windowheight">
              <div className="grid gtc-sidebar h-100">
                <div className="grs1 gre2 gcs1 gce2">
                  <Route
                    render={(props) => (
                      <Navigation {...props} authUser={this.state.authUser} />
                    )}
                  />
                </div>
                <div className="grs1 gre2 gcs2 gce3 overflow-y-scroll routeshady">
                  {this.state.authUser === null ? (
                    <>
                      <Route
                        exact
                        path={ROUTES.API}
                        render={(props) => (
                          <Api {...props} authUser={this.state.authUser} />
                        )}
                      />
                      <Redirect from={ROUTES.LANDING} to={ROUTES.HOME} />
                      <Redirect from={ROUTES.ACCOUNT} to={ROUTES.HOME} />
                      <Redirect from={ROUTES.ADMIN} to={ROUTES.HOME} />
                      <Redirect from={ROUTES.BANNERS} to={ROUTES.HOME} />
                      <Redirect from={ROUTES.STATS} to={ROUTES.HOME} />
                      <Redirect from={ROUTES.MEDAID} to={ROUTES.HOME} />
                      <Redirect from={ROUTES.CALLBACK} to={ROUTES.HOME} />
                      <Redirect from={ROUTES.TEMPLATES} to={ROUTES.HOME} />
                      <Redirect from={ROUTES.EVENTS} to={ROUTES.HOME} />
                    </>
                  ) : (
                    <>
                      <Route
                        exact
                        path={ROUTES.API}
                        render={(props) => (
                          <Api {...props} authUser={this.state.authUser} />
                        )}
                      />
                      <Route
                        exact
                        path={ROUTES.LANDING}
                        render={(props) => (
                          <Landing {...props} authUser={this.state.authUser} />
                        )}
                      />
                      <Route
                        exact
                        path={ROUTES.BANNERS}
                        render={(props) => (
                          <Banners {...props} authUser={this.state.authUser} />
                        )}
                      />
                      <Route
                        exact
                        path={ROUTES.ACCOUNT}
                        render={(props) => (
                          <Account {...props} authUser={this.state.authUser} />
                        )}
                      />
                      <Route
                        exact
                        path={ROUTES.STATS}
                        render={(props) => (
                          <Stats
                            {...props}
                            authUser={this.state.authUser}
                            usersFiltered={this.state.usersFiltered}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={ROUTES.ADMIN}
                        render={(props) => (
                          <Admin
                            {...props}
                            authUser={this.state.authUser}
                            doFilterUsers={this.filterUsers}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={ROUTES.MEDAID}
                        render={(props) => (
                          <MedicalAid
                            {...props}
                            authUser={this.state.authUser}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={ROUTES.CALLBACK}
                        render={(props) => (
                          <Callbacks
                            {...props}
                            authUser={this.state.authUser}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={ROUTES.TEMPLATES}
                        render={(props) => (
                          <Templates
                            {...props}
                            authUser={this.state.authUser}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={ROUTES.EVENTS}
                        render={(props) => (
                          <Events
                            {...props}
                            authUser={this.state.authUser}
                          />
                        )}
                      />
                    </>
                  )}

                  <Route
                    exact
                    path={ROUTES.HOME}
                    render={() =>
                      this.state.authUser !== null ? (
                        <Redirect to={ROUTES.LANDING} />
                      ) : (
                        <Home />
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </Router>
      </div>
    )
  }
}

export default withFirebase(App)
