import React, { Component } from 'react'
import { withFirebase } from '../firebase'
import Toasty from './toasty'

class Callbacks extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userLoggedInSpecified: false,
      prevAuthProp: null,
      keys: [],
      toast: null,
      callbackJsons: {},
    }
    this.keyRef = null
    this.callbackRef = null
    this.cb = 0
    this.snapshotReceived = this.snapshotReceived.bind(this)
    this.copyToClip = this.copyToClip.bind(this)
    this.listenForCB = this.listenForCB.bind(this)
  }

  componentDidMount() {
    if (this.props.authUser !== undefined && this.props.authUser !== null) {
      this.setState({
        userLoggedInSpecified: true,
        prevAuthProp: this.props.authUser,
      })
      this.keyRef = this.props.firebase.database.ref(
        'apikeys/' + this.props.authUser.uid
      )
      this.keyRef.on('value', (snapshot) => {
        this.snapshotReceived(snapshot)
      })
      this.listenForCB()
    }
  }

  componentWillUnmount() {
    if (this.keyRef !== null) {
      this.keyRef.off()
    }
  }

  componentDidUpdate() {
    if (
      this.props.authUser !== undefined &&
      this.props.authUser !== null &&
      !this.state.userLoggedInSpecified &&
      this.state.prevAuthProp !== this.props.authUser
    ) {
      this.setState({
        userLoggedInSpecified: true,
        prevAuthProp: this.props.authUser,
      })
      this.keyRef = this.props.firebase.database.ref(
        'apikeys/' + this.props.authUser.uid
      )
      this.keyRef.on('value', (snapshot) => {
        this.snapshotReceived(snapshot)
      })
      this.listenForCB()
    }
  }

  listenForCB() {
    if (this.cb === 0) {
      console.log('initializing cb')
      this.cb = 1
      this.callbackRef = this.props.firebase.database.ref(
        'callback/' + this.props.authUser.uid
      )
      this.callbackRef.on('child_added', (snapshot) => {
        console.log(snapshot)
        this.setState((stt) => {
          const cb = stt.callbackJsons
          cb[snapshot.key] = snapshot.val()
          return { callbackJsons: cb }
        })
      })
      this.callbackRef.on('child_removed', (snapshot) => {
        this.setState((stt) => {
          const cb = stt.callbackJsons
          delete cb[snapshot.key]
          return { callbackJsons: cb }
        })
      })
    }
  }

  snapshotReceived(fullSnapshot) {
    // console.log(fullSnapshot.val())
    const barr = []
    fullSnapshot.forEach((element) => {
      //   console.log(element.val())
      const oneKey = element.val()
      const o = {
        apitype: element.key,
        apikey: oneKey,
      }
      barr.push(o)
      this.setState({ keys: barr })
    })
  }

  copyToClip(event) {
    const copytext = event.target.id
    navigator.clipboard.writeText(copytext)
    this.setState({ toast: 'Copied to Clipboard' })
  }

  render() {
    return (
      <>
        <div className=" pa3">
          Here you can see all of the test callbacks received. Use this to
          simulate the data which the callback on the Medme API will be sending.
          <br /><br />Guard these addresses in the same way as the keys since they
          contain your api keys. The callback addresses are:
          <div className="w-100 h-100 pt3">
            <div className="w-100 grid banner-grid jc-start bg-medmeblue-light">
              <div className="gcs1 gce3 grs1 gre2 pa2">Environment</div>
              <div className="gcs3 gce5 grs1 gre2 pa2">
                Callback Test Address
              </div>
            </div>
            {this.state.keys.map((oneKey, i) => {
              return (
                <div
                  className={
                    (i > 0 ? 'bt b--light-silver ' : '') +
                    (i % 2 === 1 ? 'bg-near-white' : 'bg-white') +
                    ' w-100 grid banner-grid jc-start dim pointer'
                  }
                  key={i}
                >
                  <div
                    className="gcs1 gce3 grs1 gre2 pa2"
                    onClick={this.copyToClip}
                    id={`https://us-central1-support-ad895.cloudfunctions.net/callbackTest?apikey=${oneKey.apikey}&user=${this.props.authUser.uid}`}
                  >
                    {oneKey.apitype}
                  </div>
                  <div
                    className="gcs3 gce5 grs1 gre2 pa2"
                    onClick={this.copyToClip}
                    id={`https://us-central1-support-ad895.cloudfunctions.net/callbackTest?apikey=${oneKey.apikey}&user=${this.props.authUser.uid}`}
                  >
                    {`https://us-central1-support-ad895.cloudfunctions.net/callbackTest?apikey=${oneKey.apikey}&user=${this.props.authUser.uid}`}
                  </div>
                </div>
              )
            })}
          </div>
          <div
            className="bg-medmeblue white dim grow pa3 br2 pointer center mt3 mb2 dt"
            onClick={(e) => {
              this.callbackRef.set(null)
            }}
          >
            Clear callbacks
          </div>
          <pre className="pa3 bg-near-white">
            {JSON.stringify(this.state.callbackJsons, null, 2)}
          </pre>
        </div>
        <Toasty message={this.state.toast} />
      </>
    )
  }
}

export default withFirebase(Callbacks)
