import React from 'react'
import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'

import { withFirebase } from '../firebase'

class Api extends React.Component {
  
  render() {
    //   console.log(thespec)
    return (
      <div className="pt3 pb5">
        <SwaggerUI url='/spec.yaml' />
      </div>
    )
  }
}

export default withFirebase(Api)
