import React, { Component } from 'react'
import Toasty from './toasty'
import { request } from 'https'

class MedicalAid extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userLoggedInSpecified: false,
      prevAuthProp: null,
      toast: null,
      isconverted: false,
      searchfor: '',
      csvBody: '',
      objBody: {}
    }
    this.inactiveUsersRef = null
    this.activateChanges = this.activateChanges.bind(this)
  }

  componentDidMount() {
    if (this.props.authUser !== undefined && this.props.authUser !== null) {
      this.setState({
        userLoggedInSpecified: true,
        prevAuthProp: this.props.authUser
      })
    }
  }

  componentWillUnmount() {}

  componentDidUpdate() {
    if (
      this.props.authUser !== undefined &&
      this.props.authUser !== null &&
      !this.state.userLoggedInSpecified &&
      this.state.prevAuthProp !== this.props.authUser
    ) {
      this.setState({
        userLoggedInSpecified: true,
        prevAuthProp: this.props.authUser
      })
    }
  }

  convertFromCSV() {
    let csvToArr = (strData, strDelimiter) => {
      // Check to see if the delimiter is defined. If not,
      // then default to comma.
      strDelimiter = strDelimiter || ','

      // Create a regular expression to parse the CSV values.
      var objPattern = new RegExp(
        // Delimiters.
        '(\\' +
          strDelimiter +
          '|\\r?\\n|\\r|^)' +
          // Quoted fields.
          '(?:"([^"]*(?:""[^"]*)*)"|' +
          // Standard fields.
          '([^"\\' +
          strDelimiter +
          '\\r\\n]*))',
        'gi'
      )

      // Create an array to hold our data. Give the array
      // a default empty first row.
      var arrData = [[]]

      // Create an array to hold our individual pattern
      // matching groups.
      var arrMatches = null

      // Keep looping over the regular expression matches
      // until we can no longer find a match.
      while ((arrMatches = objPattern.exec(strData))) {
        // Get the delimiter that was found.
        var strMatchedDelimiter = arrMatches[1]

        // Check to see if the given delimiter has a length
        // (is not the start of string) and if it matches
        // field delimiter. If id does not, then we know
        // that this delimiter is a row delimiter.
        if (
          strMatchedDelimiter.length &&
          strMatchedDelimiter !== strDelimiter
        ) {
          // Since we have reached a new row of data,
          // add an empty row to our data array.
          arrData.push([])
        }

        var strMatchedValue

        // Now that we have our delimiter out of the way,
        // let's check to see which kind of value we
        // captured (quoted or unquoted).
        if (arrMatches[2]) {
          // We found a quoted value. When we capture
          // this value, unescape any double quotes.
          strMatchedValue = arrMatches[2].replace(new RegExp('""', 'g'), '"')
        } else {
          // We found a non-quoted value.
          strMatchedValue = arrMatches[3]
        }

        // Now that we have our value string, let's add
        // it to the data array.
        arrData[arrData.length - 1].push(strMatchedValue)
      }
      // Return the parsed data.
      return arrData
    }

    const csvStr = this.state.csvBody

    if (csvStr === '') {
      this.setState({ objBody: {} })
    } else {
      
      let o = {}
      const conv = csvToArr(csvStr, '~')

      // "admin_name"~"scheme_name"~"plan_name"~"option_name"~"actvy_status_ind"~"admin_cd"~"scheme_cd"~"plan_cd"~"option_cd"

      const numHeaders = conv[0].length;
      console.log(conv[0])
      const schemenum = conv[0].findIndex(a => a === "scheme_name")
      const plannum = conv[0].findIndex(a => a === "plan_name")
      const optionnum = conv[0].findIndex(a => a === "option_name")
      const optionkeynum = conv[0].findIndex(a => a === "option_cd")

      // 1st row is the CSV header
      for (let index = 1; index < conv.length; index++) {
        const oneRow = conv[index];
        // console.log(oneRow)

        // $ # [ ] / or .

        if (oneRow.length === numHeaders) {
          try {
            const thescheme = encodeURIComponent(oneRow[schemenum].toString().toLowerCase().replace(/ /g, '').split("(").join("_").split(")").join("_").split("/").join("_").split("[").join("_").split("]").join("_").split(".").join("_"))
            const theplan = encodeURIComponent(oneRow[plannum].toString().toLowerCase().replace(/ /g, '').split("(").join("_").split(")").join("_").split("/").join("_").split("[").join("_").split("]").join("_").split(".").join("_"))
            const theoption = oneRow[optionnum].toString()
            const optionkey = oneRow[optionkeynum].toString()
    
            if (o[thescheme] === null || o[thescheme] === undefined) {
              o[thescheme] = {name: oneRow[schemenum]}
            }
    
            if (o[thescheme][theplan] === null || o[thescheme][theplan] === undefined) {
              o[thescheme][theplan] = {
                name: oneRow[plannum],
                options: {}
              }
            }
    
            o[thescheme][theplan]['options'][optionkey] = theoption  
          } catch (error) {
            console.group('conversionError')
            console.log(error)
            console.log(oneRow)
            console.groupEnd()
          }
          
          
        }

      }

      this.setState({objBody: o})

      console.log(o)

    }

    
  }

  activateChanges() {
    const uarr = this.state.users.filter(a => a.changed === true)

    if (uarr.length === 0) {
      this.setState({ toast: 'No practice changes to process' })
    } else {
      const origusers = JSON.parse(this.origusers)

      let outUsers = []
      for (let index = 0; index < uarr.length; index++) {
        const element = uarr[index]
        const orig = origusers.filter(a => uarr[index].uid === a.uid)[0]
        let o = {}
        for (let idx = 0; idx < element.dataarr.length; idx++) {
          const onePair = element.dataarr[idx]
          if (
            orig[onePair.ukey].toString() !== onePair.uvalue.toString() ||
            onePair.ukey === 'uid'
          ) {
            o[onePair.ukey] = onePair.uvalue
          }
          if (idx === element.dataarr.length - 1) {
            outUsers.push(o)
          }
        }
        if (index === uarr.length - 1) {
          const data = JSON.stringify({
            users: outUsers
          })

          const options = {
            hostname: 'us-central1-medmeweb.cloudfunctions.net',
            port: 443,
            path: '/consoleUsersChange?admin=' + this.admin,
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Content-Length': data.length
            }
          }

          const req = request(options, res => {
            if (res.statusCode.toString() === '200') {
              this.setState({ toast: 'Practice changes synced' })
            } else {
              this.setState({ toast: 'Error in syncing practice changes' })
            }
            res.on('data', d => {
              console.log(d)
            })
          })

          req.on('error', error => {
            console.error(error)
            this.setState({ toast: 'Error in syncing practice changes' })
          })

          req.write(data)
          req.end()
        }
      }
    }
  }

  selectNone() {}

  render() {
    return (
      <>
        <div className="w-100 h-100 pl3 pr3 pt3 pb5 mb3">
          <div className="pb3">
            <button
              className={
                ((this.isconverted && this.state.csvBody.length !== 0)
                  ? 'bg-medmeblue-light grow pointer'
                  : 'bg-near-white gray strike') +
                ' bn br3 ph3 pv2 mr2 fw2 mid-gray'
              }
              onClick={
                this.activateChanges
              }
            >
              Sync Changes to MedMe
            </button>
            <button
              className={
                (this.state.csvBody.length === 0
                  ? 'bg-near-white gray strike'
                  : 'bg-medmeblue-light grow pointer') +
                ' bn br3 ph3 pv2 mr2 fw2 mid-gray'
              }
              onClick={e => {
                this.convertFromCSV()
              }}
            >
              Convert to Object
            </button>
          </div>
          <textarea
            className="w-100 medmeblue-dark no-outline br2 ws-normal h5 b--light-gray pl2 pv2 ba"
            type="textarea"
            onChange={e => {
              this.setState({ csvBody: e.target.value.toString(),
              objBody: {},
              isconverted: false })
            }}
          />
          <div className="w-100 pv3">Then save this as a JSON and upload to /medicalaid in Firebase (method to upload coming later):</div>
          <div className="w-100 mv3 pa2 bg-light-gray">{JSON.stringify(this.state.objBody)}</div>
        </div>
        <Toasty message={this.state.toast} />
      </>
    )
  }
}

export default MedicalAid
