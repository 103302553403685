import React from 'react'
import { Link } from 'react-router-dom'
import * as ROUTES from '../constants/routes'

class Navigation extends React.Component {
  // constructor(props) {
  //   super(props)
  // }

  render() {
    return (
      <div className="bg-medme-sidebar h-100 mt0 pt0 relative top-0 flex w-100">
        <ul className="list pl3">
          {this.props.authUser === null || this.props.authUser === undefined ? (
            <>
              <li className="pv2">
                <Link
                  className={
                    'no-outline link fw3 tracked ' +
                    (this.props.location.pathname === ROUTES.HOME
                      ? 'medmeblue-dark'
                      : 'white')
                  }
                  to={ROUTES.HOME}
                >
                  Home
                </Link>
              </li>
              <li className="pv2">
                <Link
                  className={
                    'no-outline link fw3 tracked ' +
                    (this.props.location.pathname === ROUTES.API
                      ? 'medmeblue-dark'
                      : 'white')
                  }
                  to={ROUTES.API}
                >
                  API
                </Link>
              </li>
            </>
          ) : null}
          {this.props.authUser === null ||
          this.props.authUser === undefined ? null : (
            <>
              <li className="pv2">
                <Link
                  className={
                    'no-outline link fw3 tracked ' +
                    (this.props.location.pathname === ROUTES.LANDING
                      ? 'medmeblue-dark'
                      : 'white')
                  }
                  to={ROUTES.LANDING}
                >
                  Landing
                </Link>
              </li>
              <li className="pv2">
                <Link
                  className={
                    'no-outline link fw3 tracked ' +
                    (this.props.location.pathname === ROUTES.BANNERS
                      ? 'medmeblue-dark'
                      : 'white')
                  }
                  to={ROUTES.BANNERS}
                >
                  Banners
                </Link>
              </li>
              <li className="pv2">
                <Link
                  className={
                    'no-outline link fw3 tracked ' +
                    (this.props.location.pathname === ROUTES.API
                      ? 'medmeblue-dark'
                      : 'white')
                  }
                  to={ROUTES.API}
                >
                  API
                </Link>
              </li>
              <li className="pv2">
                <Link
                  className={
                    'no-outline link fw3 tracked ' +
                    (this.props.location.pathname === ROUTES.ACCOUNT
                      ? 'medmeblue-dark'
                      : 'white')
                  }
                  to={ROUTES.ACCOUNT}
                >
                  Account
                </Link>
              </li>
              <li className="pv2">
                <Link
                  className={
                    'no-outline link fw3 tracked ' +
                    (this.props.location.pathname === ROUTES.STATS
                      ? 'medmeblue-dark'
                      : 'white')
                  }
                  to={ROUTES.STATS}
                >
                  Stats
                </Link>
              </li>
              <li className="pv2">
                <Link
                  className={
                    'no-outline link fw3 tracked ' +
                    (this.props.location.pathname === ROUTES.ADMIN
                      ? 'medmeblue-dark'
                      : 'white')
                  }
                  to={ROUTES.ADMIN}
                >
                  Admin
                </Link>
              </li>
              <li className="pv2">
                <Link
                  className={
                    'no-outline link fw3 tracked ' +
                    (this.props.location.pathname === ROUTES.MEDAID
                      ? 'medmeblue-dark'
                      : 'white')
                  }
                  to={ROUTES.MEDAID}
                >
                  Medical Aid
                </Link>
              </li>
              <li className="pv2">
                <Link
                  className={
                    'no-outline link fw3 tracked ' +
                    (this.props.location.pathname === ROUTES.CALLBACK
                      ? 'medmeblue-dark'
                      : 'white')
                  }
                  to={ROUTES.CALLBACK}
                >
                  Callbacks
                </Link>
              </li>
              <li className="pv2">
                <Link
                  className={
                    'no-outline link fw3 tracked ' +
                    (this.props.location.pathname === ROUTES.EVENTS
                      ? 'medmeblue-dark'
                      : 'white')
                  }
                  to={ROUTES.EVENTS}
                >
                  Events
                </Link>
              </li>
              <li className="pv2">
                <Link
                  className={
                    'no-outline link fw3 tracked ' +
                    (this.props.location.pathname === ROUTES.TEMPLATES
                      ? 'medmeblue-dark'
                      : 'white')
                  }
                  to={ROUTES.TEMPLATES}
                >
                  Templates
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    )
  }
}

export default Navigation
