import React, { Component } from 'react'
import { withFirebase } from '../firebase'
import Toasty from './toasty'

class Account extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userLoggedInSpecified: false,
      prevAuthProp: null,
      keys: [],
      toast: null
    }
    this.keyRef = null
    this.snapshotReceived = this.snapshotReceived.bind(this)
    this.copyToClip = this.copyToClip.bind(this)
  }

  componentDidMount() {
    if (this.props.authUser !== undefined && this.props.authUser !== null) {
      this.setState({
        userLoggedInSpecified: true,
        prevAuthProp: this.props.authUser
      })
      this.keyRef = this.props.firebase.database.ref(
        'apikeys/' + this.props.authUser.uid
      )
      this.keyRef.on('value', snapshot => {
        this.snapshotReceived(snapshot)
      })
    }
  }

  componentWillUnmount() {
    if (this.keyRef !== null) {
      this.keyRef.off()
    }
  }

  componentDidUpdate() {
    if (
      this.props.authUser !== undefined &&
      this.props.authUser !== null &&
      !this.state.userLoggedInSpecified &&
      this.state.prevAuthProp !== this.props.authUser
    ) {
      this.setState({
        userLoggedInSpecified: true,
        prevAuthProp: this.props.authUser
      })
      this.keyRef = this.props.firebase.database.ref(
        'apikeys/' + this.props.authUser.uid
      )
      this.keyRef.on('value', snapshot => {
        this.snapshotReceived(snapshot)
      })
    }
  }

  snapshotReceived(fullSnapshot) {
    // console.log(fullSnapshot.val())
    const barr = []
    fullSnapshot.forEach(element => {
      //   console.log(element.val())
      const oneKey = element.val()
      const o = {
        apitype: element.key,
        apikey: oneKey
      }
      barr.push(o)
      this.setState({ keys: barr })
    })
  }

  copyToClip(event) {
    const copytext = event.target.id
    navigator.clipboard.writeText(copytext)
    this.setState({ toast: 'Copied to Clipboard' })
  }

  render() {
    return (
      <>
        <div className="w-100 h-100 pa3">
          <div className="w-100 grid banner-grid jc-start bg-medmeblue-light">
            <div className="gcs1 gce3 grs1 gre2 pa2">Environment</div>
            <div className="gcs3 gce5 grs1 gre2 pa2">Api Key</div>
          </div>
          {this.state.keys.map((oneKey, i) => {
            return (
              <div
                className={
                  (i > 0 ? 'bt b--light-silver ' : '') +
                  (i % 2 === 1 ? 'bg-near-white' : 'bg-white') +
                  ' w-100 grid banner-grid jc-start dim pointer'
                }
                key={i}
              >
                <div
                  className="gcs1 gce3 grs1 gre2 pa2"
                  onClick={this.copyToClip}
                  id={oneKey.apikey}
                >
                  {oneKey.apitype}
                </div>
                <div
                  className="gcs3 gce5 grs1 gre2 pa2"
                  onClick={this.copyToClip}
                  id={oneKey.apikey}
                >
                  {oneKey.apikey}
                </div>
              </div>
            )
          })}
        </div>
        <Toasty message={this.state.toast} />
      </>
    )
  }
}

export default withFirebase(Account)
