import React, { Component } from 'react'
import { withFirebase } from '../firebase'
import Toasty from './toasty'

class Banners extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userLoggedInSpecified: false,
      prevAuthProp: null,
      banners: [],
      toast: null
    }
    this.bannerRef = null
    this.snapshotReceived = this.snapshotReceived.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.changeSelect = this.changeSelect.bind(this)
    this.selectAll = this.selectAll.bind(this)
    this.selectNone = this.selectNone.bind(this)
    this.sendReply = this.sendReply.bind(this)
  }

  componentDidMount() {
    if (this.props.authUser !== undefined && this.props.authUser !== null) {
      this.setState({
        userLoggedInSpecified: true,
        prevAuthProp: this.props.authUser
      })
      this.bannerRef = this.props.firebase.database.ref('banners')
      this.bannerRef.on('child_added', snapshot => {
        this.snapshotReceived(snapshot)
      })
    }
  }

  componentWillUnmount() {
    if (this.bannerRef !== null) {
      this.bannerRef.off()
    }
  }

  componentDidUpdate() {
    if (
      this.props.authUser !== undefined &&
      this.props.authUser !== null &&
      !this.state.userLoggedInSpecified &&
      this.state.prevAuthProp !== this.props.authUser
    ) {
      this.setState({
        userLoggedInSpecified: true,
        prevAuthProp: this.props.authUser
      })
      this.bannerRef = this.props.firebase.database.ref('banners')
      this.bannerRef.on('child_added', snapshot => {
        this.snapshotReceived(snapshot)
      })
    }
  }

  handleCheck(event) {
    console.log(event.target.name)
    const bnrs = this.state.banners
    const indx = bnrs.findIndex(a => a.key === event.target.name)
    bnrs[indx].selected = !bnrs[indx].selected
    this.setState({
      banners: bnrs
    })
  }

  selectAll() {
    this.changeSelect(true)
  }

  selectNone() {
    this.changeSelect(false)
  }

  changeSelect(setTo) {
    const bnrs = this.state.banners
    for (let index = 0; index < bnrs.length; index++) {
      bnrs[index].selected = setTo
      if (index === (bnrs.length - 1)) {
        this.setState({
          banners: bnrs
        })
      }
    }
  }

  sendReply() {
    const bnrs = this.state.banners
    // console.log(bnrs.filter(a => a.selected === true).length)
    if (bnrs.filter(a => a.selected === true).length === 0) {
      this.setState({toast: 'No banners selected'})
    } else {

    }
  }

  snapshotReceived(fullSnapshot) {
    // console.log(fullSnapshot.val())
    const barr = this.state.banners
    fullSnapshot.forEach(element => {
      //   console.log(element.val())
      const oneBanner = element.val()
      const outBanner = {
        selected: false,
        key: element.key,
        user: fullSnapshot.key,
        candelete: oneBanner.candelete === true ? 'Yes' : 'No',
        expirytime: oneBanner.expirytime,
        senttime: oneBanner.senttime,
        actioned: oneBanner.actioned === true ? 'Yes' : 'No',
        message: oneBanner.message,
        response:
          oneBanner.response === null || oneBanner.response === undefined
            ? ''
            : oneBanner.response,
        // if it is a threaded set of messages, we want to have the ability
        //  to show a single thread at a time
        thread:
          oneBanner.thread === null || oneBanner.thread === undefined
            ? ''
            : oneBanner.thread
      }

      barr.push(outBanner)
      // default sort order is by sent time
      barr.sort(a => a.senttime)
      this.setState({ banners: barr })
    })
  }

  render() {
    return (
      <>
      <div className="w-100 h-100 pa3">
        <div className="pb3">
          <button
            className="bg-medmeblue-light bn grow br3 ph3 pv2 mr2 fw2 mid-gray"
            onClick={this.selectAll}
          >
            Select All
          </button>
          <button
            className="bg-medmeblue-light bn grow br3 ph3 pv2 mr2 fw2 mid-gray"
            onClick={this.selectNone}
          >
            Select None
          </button>
          <button
            className="bg-medmeblue-light bn grow br3 ph3 pv2 mr2 fw2 mid-gray"
            onClick={this.signOut}
          >
            Show Thread
          </button>
          <button
            className="bg-medmeblue-light bn grow br3 ph3 pv2 mr2 fw2 mid-gray"
            onClick={this.sendReply}
          >
            Send Reply
          </button>
        </div>
        <div className="w-100 grid banner-grid jc-start bg-medmeblue-light">
          <div className="gcs1 gce2 grs1 gre2 pa2">
            <div className="pl1">x</div>
          </div>
          <div className="gcs2 gce3 grs1 gre2 pa2">Actioned</div>
          <div className="gcs3 gce4 grs1 gre2 pa2">Message</div>
          <div className="gcs4 gce5 grs1 gre2 pa2">Response</div>
        </div>
        {this.state.banners.map((oneBanner, i) => {
          return (
            <div
              className={
                (i > 0 ? 'bt b--light-silver ' : '') +
                (i % 2 === 1 ? 'bg-near-white' : 'bg-white') +
                ' w-100 grid banner-grid jc-start dim'
              }
              key={i}
            >
              <div className="gcs1 gce2 grs1 gre2 pa2">
                <input
                  type="checkbox"
                  checked={oneBanner.selected}
                  onChange={this.handleCheck}
                  name={oneBanner.key}
                />
              </div>
              <div className="gcs2 gce3 grs1 gre2 pa2">
                {oneBanner.actioned}
              </div>
              <div className="gcs3 gce4 grs1 gre2 pa2">{oneBanner.message}</div>
              <div className="gcs4 gce5 grs1 gre2 pa2">
                {oneBanner.response}
              </div>
            </div>
          )
        })}
      </div>
      <Toasty message={this.state.toast} />
      </>
    )
  }
}

export default withFirebase(Banners)
