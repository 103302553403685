import React from 'react'
import ReactDOM from 'react-dom'

import './style/medme.css'
import './style/tachyons.css'

import App from './components/app'
import Firebase, { FirebaseContext } from './firebase'

// import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <App />
  </FirebaseContext.Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
