import React from 'react'
import { Link } from 'react-router-dom'
import * as ROUTES from '../constants/routes'

class Landing extends React.Component {
  render() {
    return (
      <div className="pt3 pb3 pl4 pr4">
        <h2 className="medmegreen-dark fw3 tracked">Welcome to the MedMe Console</h2>
        <div>
          Designed exclusively for the healthcare industry, MedMe builds
          products that provide a solid foundation for health communication that
          scales along with your health facility—to allow for a more intuitive
          medical experience today and into the future.
          <br />
          <br />
        </div>

        <h3>
          <Link className="link tracked medmeblue-dark fw3" to={ROUTES.BANNERS}>
            Banners
          </Link>
        </h3>
        <div className="pl3">
          Practices using the MedMe application will receive, and can respond
          to, banner messaging. On the Banners page, new banners can be created
          and banner replies can be monitored.
        </div>
        <h3>
          <Link className="link tracked medmeblue-dark fw3" to={ROUTES.API}>
            MedMe API
          </Link>
        </h3>
        <div className="pl3">
          For participating partners such as CGM, MedMe offers a feature rich
          API. The API allows for features such as creating appointments,
          administering practice accounts, etc. On the API page, a detailed
          specification of the API can be found.
        </div>
        <h3>
          <Link className="link tracked medmeblue-dark fw3" to={ROUTES.ACCOUNT}>
            Account
          </Link>
        </h3>
        <div className="pl3">
          API keys are needed in order to utilise the RESTful API. On the
          Account page the API keys can be maintained.
        </div>
        <h3>
          <Link className="link tracked medmeblue-dark fw3" to={ROUTES.STATS}>
            Stats
          </Link>
        </h3>
        <div className="pl3">
          MedMe offers practices easy mechanisms to create appointments, receive
          confirmations from patients, and process patient forms before the
          appointment starts.
          <br />
          <br />
          On the statistics page, the appointments created, confirmed and forms
          received over time can be tracked. It is also possible to filter the
          statistics for any one of the member practices.
        </div>
        <h3>
          <Link className="link tracked medmeblue-dark fw3" to={ROUTES.ADMIN}>
            Administration
          </Link>
        </h3>
        <div className="pl3">
          Several different details of the practice accounts can be changed.
          Examples are: enabling/disabling accounts, changing account addresses,
          to name but a few.
        </div>
      </div>
    )
  }
}

export default Landing
