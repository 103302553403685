export const LANDING = '/landing'
export const HOME = '/'
export const ACCOUNT = '/account'
export const ADMIN = '/admin'
export const BANNERS = '/banners'
export const API = '/api'
export const STATS = '/stats'
export const MEDAID = '/medicalaid'
export const CALLBACK = '/callbacks'
export const TEMPLATES = '/templates'
export const EVENTS = '/events'
