import React, { Fragment } from 'react'
import { withFirebase } from '../firebase'
import Toasty from './toasty'
import { VictoryLine, VictoryChart, VictoryAxis, VictoryLabel } from 'victory'

const verbose = false

const modes = {
  stats: { next: 'monthly', label: 'Curves' },
  monthly: { next: 'stats', label: 'Invoice' },
}

const sortdesc = (a, b) => {
  if (a > b) {
    return -1
  } else {
    return 1
  }
}

const sortInvoice = (a, b) => {
  const atot = a.created + a.acknowledged + a.deleted + a.changed
  const btot = b.created + b.acknowledged + b.deleted + b.changed
  if (a.created > b.created) {
    return -1
  } else if (a.created < b.created) {
    return 1
  } else if (atot > btot) {
    return -1
  } else {
    return 1
  }
}

const sortInvoicePracticeId = (a, b) => {
  if (a.practiceid.toLowerCase() > b.practiceid.toLowerCase()) {
    return 1
  } else {
    return -1
  }
}

const sortInvoiceShort = (a, b) => {
  if (a.short.toLowerCase() > b.short.toLowerCase()) {
    return 1
  } else {
    return -1
  }
}

class Stats extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userLoggedInSpecified: false,
      prevAuthProp: null,
      stats: {},
      userStats: {},
      filteredUsers: {},
      users: [],
      months: [],
      month: '',
      toast: null,
      victoryData: null,
      show: 'All',
      graphHeight: 600,
      graphWidth: 400,
      mode: 'stats',
      apiAccount: '',
      userListFiltered: [],
      sortInvoiceFun: sortInvoice,
    }
    this.shorts = []
    this.subUsersFilter = []
    this.apiUsers = [{ uid: '', name: 'Other' }]
    this.victoryAnimate = 5000
    this.victoryOnLoad = 3000
    this.users = []
    this.fullRef = React.createRef()
    this.buttonBoxRef = React.createRef()
    this.statsRef = null
    this.usersRef = null
    this.apiUsersRef = null
    this.handleChange = this.handleChange.bind(this)
    this.selectAll = this.selectAll.bind(this)
    this.renderChart = this.renderChart.bind(this)
    this.fetchAll = this.fetchAll.bind(this)
    this.fullSnapshotReceived = this.fullSnapshotReceived.bind(this)
    this.findShort = this.findShort.bind(this)
    this.adminFilter = this.adminFilter.bind(this)
    this.filterUsers = this.filterUsers.bind(this)
    this.invoiceRender = this.invoiceRender.bind(this)
    this.createCSV = this.createCSV.bind(this)
    this.getReducedList = this.getReducedList.bind(this)
  }

  componentDidMount() {
    if (this.props.authUser !== undefined && this.props.authUser !== null) {
      this.setState({
        userLoggedInSpecified: true,
        prevAuthProp: this.props.authUser,
        users: [],
      })
      this.fetchAll()
    }
    this.setState({
      graphHeight:
        this.fullRef.current.getBoundingClientRect().height -
        this.buttonBoxRef.current.getBoundingClientRect().height -
        40,
      graphWidth: this.fullRef.current.getBoundingClientRect().width - 40,
    })
  }

  componentWillUnmount() {
    if (this.statsRef !== null) {
      this.statsRef.off()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (verbose) console.log(this.props.usersFiltered)
    if (
      this.props.authUser !== undefined &&
      this.props.authUser !== null &&
      !this.state.userLoggedInSpecified &&
      this.state.prevAuthProp !== this.props.authUser
    ) {
      this.setState({
        userLoggedInSpecified: true,
        prevAuthProp: this.props.authUser,
        users: [],
      })
      this.fetchAll()
    }
    if (prevState.months !== this.state.months) {
      const mths = JSON.parse(JSON.stringify(this.state.months)).sort(sortdesc)
      this.setState({ month: mths[0] })
    }
    if (
      (prevState.mode !== this.state.mode && this.state.mode === 'monthly') ||
      this.state.apiAccount !== prevState.apiAccount
    ) {
      if (this.state.month === '') {
        this.setState({ month: this.state.months.sort(sortdesc)[0] })
      }
      this.filterUsers()
    }
    if (prevState.userListFiltered !== this.state.userListFiltered) {
      if (verbose) console.log(this.state.userListFiltered)
    }
  }

  filterUsers() {
    if (this.state.apiAccount === '') {
      this.setState({
        userListFiltered: JSON.parse(JSON.stringify(this.shorts)).filter(
          (u) => this.apiUsers.findIndex((a) => a.uid === u.console) === -1
        ),
      })
    } else if (this.state.apiAccount !== '' || this.apiUsers.length > 0) {
      const apiacc =
        this.state.apiAccount === ''
          ? this.apiUsers[0].uid
          : this.state.apiAccount
      this.setState({
        userListFiltered: JSON.parse(JSON.stringify(this.shorts)).filter(
          (u) => u.console === apiacc
        ),
      })
    }
  }

  async fetchAll() {
    this.statsRef = this.props.firebase.database.ref('stats')
    this.usersRef = this.props.firebase.database.ref('users')
    const statsArr = []
    this.apiUsersRef = this.props.firebase.database.ref('apiusers')
    if (verbose) console.log('fetching api users')
    await this.apiUsersRef.on('child_added', async (snapshot) => {
      const o = {
        uid: snapshot.key,
        name: snapshot.val().name,
      }
      if (
        snapshot.key === this.props.authUser.uid ||
        this.props.authUser.uid === 'gKnjQPXp4pf0920MDWT7I5vMGt63'
      ) {
        const idx = this.apiUsers.findIndex((a) => a.uid === snapshot.key)
        if (idx === -1) {
          this.apiUsers.push(o)
        } else {
          this.apiUsers[idx] = o
        }
        if (verbose) console.log(this.apiUsers)
        this.filterUsers()
      }
    })
    if (verbose) console.log('fetching practices')
    this.usersRef.on('child_added', (snapshot) => {
      const o = {
        uid: snapshot.key,
        short: snapshot.val().short,
        console: snapshot.val().console,
        enabled: snapshot.val().enabled,
        practiceid: snapshot.val().practiceid || snapshot.key,
      }
      const idx = this.shorts.findIndex((a) => a.uid === snapshot.key)
      if (idx === -1) {
        this.shorts.push(o)
      } else {
        this.shorts[idx] = o
      }
      if (
        snapshot.val().console === this.props.authUser.uid ||
        this.props.authUser.uid === 'gKnjQPXp4pf0920MDWT7I5vMGt63'
      ) {
        const idy = this.subUsersFilter.findIndex((a) => a.uid === snapshot.key)
        if (idy === -1) {
          this.subUsersFilter.push(o)
        } else {
          this.subUsersFilter[idy] = o
        }
      }
      this.filterUsers()
    })
    if (verbose) console.log('fetching stats')
    await this.statsRef.on('value', async (snapshot) => {
      const keysArr = Object.keys(snapshot.val())
      this.setState({ users: keysArr })
      await keysArr.forEach((oneEl) => {
        statsArr.push({ snap: snapshot.val()[oneEl], user: oneEl })
        if (oneEl === keysArr[keysArr.length - 1]) {
          this.fullSnapshotReceived(statsArr)
        }
      })
    })
  }

  findShort(theUid) {
    const idx = this.shorts.findIndex((a) => a.uid === theUid)
    if (idx === -1) {
      return theUid
    } else {
      return this.shorts[idx].short
    }
  }

  selectAll() {
    this.setState({ show: 'All' })
    this.state.show = 'All'
    this.renderChart()
  }

  adminFilter() {
    if (verbose) console.log('applying admin fiter')
    const filteredUsers = {}
    const theFilter = this.props.usersFiltered
    for (let index = 0; index < theFilter.length; index++) {
      const element = theFilter[index]
      filteredUsers[element] = this.state.userStats[element]
    }

    this.setState({ show: 'AdminFilter', filteredUsers: filteredUsers })

    if (verbose) console.log(filteredUsers)

    setTimeout(() => {
      this.renderChart()
    }, 500)
  }

  handleChange(event) {
    this.setState({ show: event.target.value })
    this.state.show = event.target.value
    this.victoryAnimate = 1000
    this.victoryOnLoad = 1000
    this.renderChart()
  }

  fullSnapshotReceived(fullArray) {
    const statarr = this.state.stats
    const usrSts = this.state.userStats
    const arrLen = fullArray.length

    if (this.state.userListFiltered)
      for (let index = 0; index < arrLen; index++) {
        const onesnap = fullArray[index]
        const fullSnapshotKeys = Object.keys(onesnap.snap)
        const theUser = onesnap.user
        fullSnapshotKeys.forEach((oneKey) => {
          const element = onesnap.snap[oneKey]
          const oneStat = element

          let doAdd = true
          if (this.state.months.findIndex((a) => a === oneKey) === -1) {
            // new month
            this.state.months.push(oneKey)
            doAdd = false
          }

          if (doAdd) {
            statarr[oneKey] = {
              acknowledged:
                statarr[oneKey].acknowledged +
                (oneStat.acknowledged ? oneStat.acknowledged : 0),
              changed:
                statarr[oneKey].changed +
                (oneStat.changed ? oneStat.changed : 0),
              created:
                statarr[oneKey].created +
                (oneStat.created ? oneStat.created : 0),
              deleted:
                statarr[oneKey].deleted +
                (oneStat.deleted ? oneStat.deleted : 0),
              fbreceived:
                statarr[oneKey].fbreceived +
                (oneStat.fbreceived ? oneStat.fbreceived : 0),
              feedback:
                statarr[oneKey].feedback +
                (oneStat.feedback ? oneStat.feedback : 0),
              patientforms:
                statarr[oneKey].patientforms +
                (oneStat.patientforms ? oneStat.patientforms : 0),
              period: statarr[oneKey].period,
              reminder:
                statarr[oneKey].reminder +
                (oneStat.reminder ? oneStat.reminder : 0),
              reminderemail:
                statarr[oneKey].reminderemail +
                (oneStat.reminderemail ? oneStat.reminderemail : 0),
              remindersms:
                statarr[oneKey].remindersms +
                (oneStat.remindersms ? oneStat.remindersms : 0),
              smsparts:
                statarr[oneKey].smsparts +
                (oneStat.smsparts ? oneStat.smsparts : 0),
              revper: statarr[oneKey].revper,
            }
          } else {
            statarr[oneKey] = {
              acknowledged: oneStat.acknowledged ? oneStat.acknowledged : 0,
              changed: oneStat.changed ? oneStat.changed : 0,
              created: oneStat.created ? oneStat.created : 0,
              deleted: oneStat.deleted ? oneStat.deleted : 0,
              fbreceived: oneStat.fbreceived ? oneStat.fbreceived : 0,
              feedback: oneStat.feedback ? oneStat.feedback : 0,
              patientforms: oneStat.patientforms ? oneStat.patientforms : 0,
              period: oneStat.period,
              reminder: oneStat.reminder ? oneStat.reminder : 0,
              reminderemail: oneStat.reminderemail ? oneStat.reminderemail : 0,
              remindersms: oneStat.remindersms ? oneStat.remindersms : 0,
              smsparts: oneStat.smsparts ? oneStat.smsparts : 0,
              revper: oneStat.revper,
            }
          }

          if (usrSts[theUser] === undefined) {
            usrSts[theUser] = {}
          }

          usrSts[theUser][oneKey] = {
            acknowledged: oneStat.acknowledged ? oneStat.acknowledged : 0,
            changed: oneStat.changed ? oneStat.changed : 0,
            created: oneStat.created ? oneStat.created : 0,
            deleted: oneStat.deleted ? oneStat.deleted : 0,
            fbreceived: oneStat.fbreceived ? oneStat.fbreceived : 0,
            feedback: oneStat.feedback ? oneStat.feedback : 0,
            patientforms: oneStat.patientforms ? oneStat.patientforms : 0,
            period: oneStat.period,
            reminder: oneStat.reminder ? oneStat.reminder : 0,
            reminderemail: oneStat.reminderemail ? oneStat.reminderemail : 0,
            remindersms: oneStat.remindersms ? oneStat.remindersms : 0,
            smsparts: oneStat.smsparts ? oneStat.smsparts : 0,
            revper: oneStat.revper,
          }

          if (index === arrLen - 1) {
            this.setState({ stats: statarr, userStats: usrSts })
            this.renderChart()
          }
        })
      }
  }

  renderChart() {
    function doSort(a, b) {
      return a < b ? -1 : 1
    }

    let allMths = this.state.months
    allMths.sort(doSort)

    let chartDatas = {}
    let dataObj =
      this.state.show === 'All'
        ? this.state.stats
        : this.state.show === 'AdminFilter'
        ? this.state.filteredUsers
        : this.state.userStats[this.state.show]

    if (this.state.show === 'AdminFilter') {
      if (verbose) console.log('applying admin filter')
      dataObj = {}
      for (let index = 0; index < allMths.length; index++) {
        const oneMonth = allMths[index]
        dataObj[oneMonth] = {
          acknowledged: 0,
          changed: 0,
          created: 0,
          deleted: 0,
          fbreceived: 0,
          feedback: 0,
          patientforms: 0,
          reminder: 0,
          reminderemail: 0,
          remindersms: 0,
          smsparts: 0,
        }
        for (let uix = 0; uix < this.props.usersFiltered.length; uix++) {
          const oneUser = this.props.usersFiltered[uix]
          if (
            this.state.filteredUsers[oneUser][oneMonth] !== null &&
            this.state.filteredUsers[oneUser][oneMonth] !== undefined
          ) {
            dataObj[oneMonth].acknowledged = this.state.filteredUsers[oneUser][
              oneMonth
            ].acknowledged
              ? dataObj[oneMonth].acknowledged +
                this.state.filteredUsers[oneUser][oneMonth].acknowledged
              : dataObj[oneMonth].acknowledged
            dataObj[oneMonth].changed = this.state.filteredUsers[oneUser][
              oneMonth
            ].changed
              ? dataObj[oneMonth].changed +
                this.state.filteredUsers[oneUser][oneMonth].changed
              : dataObj[oneMonth].changed
            dataObj[oneMonth].created = this.state.filteredUsers[oneUser][
              oneMonth
            ].created
              ? dataObj[oneMonth].created +
                this.state.filteredUsers[oneUser][oneMonth].created
              : dataObj[oneMonth].created
            dataObj[oneMonth].deleted = this.state.filteredUsers[oneUser][
              oneMonth
            ].deleted
              ? dataObj[oneMonth].deleted +
                this.state.filteredUsers[oneUser][oneMonth].deleted
              : dataObj[oneMonth].deleted
            dataObj[oneMonth].fbreceived = this.state.filteredUsers[oneUser][
              oneMonth
            ].fbreceived
              ? dataObj[oneMonth].fbreceived +
                this.state.filteredUsers[oneUser][oneMonth].fbreceived
              : dataObj[oneMonth].fbreceived
            dataObj[oneMonth].feedback = this.state.filteredUsers[oneUser][
              oneMonth
            ].feedback
              ? dataObj[oneMonth].feedback +
                this.state.filteredUsers[oneUser][oneMonth].feedback
              : dataObj[oneMonth].feedback
            dataObj[oneMonth].patientforms = this.state.filteredUsers[oneUser][
              oneMonth
            ].patientforms
              ? dataObj[oneMonth].patientforms +
                this.state.filteredUsers[oneUser][oneMonth].patientforms
              : dataObj[oneMonth].patientforms
            dataObj[oneMonth].reminder = this.state.filteredUsers[oneUser][
              oneMonth
            ].reminder
              ? dataObj[oneMonth].reminder +
                this.state.filteredUsers[oneUser][oneMonth].reminder
              : dataObj[oneMonth].reminder
            dataObj[oneMonth].reminderemail = this.state.filteredUsers[oneUser][
              oneMonth
            ].reminderemail
              ? dataObj[oneMonth].reminderemail +
                this.state.filteredUsers[oneUser][oneMonth].reminderemail
              : dataObj[oneMonth].reminderemail
            dataObj[oneMonth].remindersms = this.state.filteredUsers[oneUser][
              oneMonth
            ].remindersms
              ? dataObj[oneMonth].remindersms +
                this.state.filteredUsers[oneUser][oneMonth].remindersms
              : dataObj[oneMonth].remindersms
            dataObj[oneMonth].smsparts = this.state.filteredUsers[oneUser][
              oneMonth
            ].smsparts
              ? dataObj[oneMonth].smsparts +
                this.state.filteredUsers[oneUser][oneMonth].smsparts
              : dataObj[oneMonth].smsparts
          }
        }
      }
    } else {
      if (verbose) console.log('applying api user filter')
      dataObj = {}
      for (let index = 0; index < allMths.length; index++) {
        const oneMonth = allMths[index]
        dataObj[oneMonth] = {
          acknowledged: 0,
          changed: 0,
          created: 0,
          deleted: 0,
          fbreceived: 0,
          feedback: 0,
          patientforms: 0,
          reminder: 0,
          reminderemail: 0,
          remindersms: 0,
          smsparts: 0,
        }
        for (let uix = 0; uix < this.subUsersFilter.length; uix++) {
          const oneUser = this.subUsersFilter[uix].uid
          // if (verbose) console.log(oneUser)
          if (
            this.state.userStats[oneUser] !== null &&
            this.state.userStats[oneUser] !== undefined &&
            this.state.userStats[oneUser][oneMonth] !== null &&
            this.state.userStats[oneUser][oneMonth] !== undefined
          ) {
            dataObj[oneMonth].acknowledged = this.state.userStats[oneUser][
              oneMonth
            ].acknowledged
              ? dataObj[oneMonth].acknowledged +
                this.state.userStats[oneUser][oneMonth].acknowledged
              : dataObj[oneMonth].acknowledged
            dataObj[oneMonth].changed = this.state.userStats[oneUser][oneMonth]
              .changed
              ? dataObj[oneMonth].changed +
                this.state.userStats[oneUser][oneMonth].changed
              : dataObj[oneMonth].changed
            dataObj[oneMonth].created = this.state.userStats[oneUser][oneMonth]
              .created
              ? dataObj[oneMonth].created +
                this.state.userStats[oneUser][oneMonth].created
              : dataObj[oneMonth].created
            dataObj[oneMonth].deleted = this.state.userStats[oneUser][oneMonth]
              .deleted
              ? dataObj[oneMonth].deleted +
                this.state.userStats[oneUser][oneMonth].deleted
              : dataObj[oneMonth].deleted
            dataObj[oneMonth].fbreceived = this.state.userStats[oneUser][
              oneMonth
            ].fbreceived
              ? dataObj[oneMonth].fbreceived +
                this.state.userStats[oneUser][oneMonth].fbreceived
              : dataObj[oneMonth].fbreceived
            dataObj[oneMonth].feedback = this.state.userStats[oneUser][oneMonth]
              .feedback
              ? dataObj[oneMonth].feedback +
                this.state.userStats[oneUser][oneMonth].feedback
              : dataObj[oneMonth].feedback
            dataObj[oneMonth].patientforms = this.state.userStats[oneUser][
              oneMonth
            ].patientforms
              ? dataObj[oneMonth].patientforms +
                this.state.userStats[oneUser][oneMonth].patientforms
              : dataObj[oneMonth].patientforms
            dataObj[oneMonth].reminder = this.state.userStats[oneUser][oneMonth]
              .reminder
              ? dataObj[oneMonth].reminder +
                this.state.userStats[oneUser][oneMonth].reminder
              : dataObj[oneMonth].reminder
            dataObj[oneMonth].reminderemail = this.state.userStats[oneUser][
              oneMonth
            ].reminderemail
              ? dataObj[oneMonth].reminderemail +
                this.state.userStats[oneUser][oneMonth].reminderemail
              : dataObj[oneMonth].reminderemail
            dataObj[oneMonth].remindersms = this.state.userStats[oneUser][
              oneMonth
            ].remindersms
              ? dataObj[oneMonth].remindersms +
                this.state.userStats[oneUser][oneMonth].remindersms
              : dataObj[oneMonth].remindersms
            dataObj[oneMonth].smsparts = this.state.userStats[oneUser][oneMonth]
              .smsparts
              ? dataObj[oneMonth].smsparts +
                this.state.userStats[oneUser][oneMonth].smsparts
              : dataObj[oneMonth].smsparts
          }
        }
      }
    }

    // if (verbose) console.log(dataObj)

    const datavals = []
    for (let index = 0; index < allMths.length; index++) {
      const element = allMths[index]
      if (dataObj[element] !== null && dataObj[element] !== undefined) {
        datavals.push(dataObj[element])
      } else {
        datavals.push({
          acknowledged: 0,
          changed: 0,
          created: 0,
          deleted: 0,
          fbreceived: 0,
          feedback: 0,
          patientforms: 0,
          reminder: 0,
          reminderemail: 0,
          remindersms: 0,
          smsparts: 0,
        })
      }
      if (index === allMths.length - 1) {
        if (verbose) console.log(datavals)
        const dtaCombined = datavals.map((a, idx) => ({
          mth: allMths[idx],
          vals: a,
        }))

        const dtaFilter = dtaCombined.filter(
          (a) =>
            a.vals.created +
              a.vals.acknowledged +
              a.vals.patientforms +
              a.vals.smsparts >
            0
        )

        chartDatas['Appointments'] = dtaFilter.map((a, idx) => ({
          x: a.mth,
          y: a.vals.created,
          // label: a.vals.created + ' appointments'
        }))
        chartDatas['Confirmed'] = dtaFilter.map((a, idx) => ({
          x: a.mth,
          y: a.vals.acknowledged,
          // label: a.vals.acknowledged + ' confirmed'
        }))
        chartDatas['Forms'] = dtaFilter.map((a, idx) => ({
          x: a.mth,
          y: a.vals.patientforms,
          // label: a.vals.patientforms + ' forms'
        }))
        chartDatas['SMSParts'] = dtaFilter.map((a, idx) => ({
          x: a.mth,
          y: a.vals.smsparts,
          // label: a.vals.smsparts + ' parts'
        }))

        this.setState({
          victoryData: chartDatas,
        })
        if (verbose) console.log(chartDatas)
      }
    }
  }

  getReducedList() {
    const reducedList = this.state.userListFiltered
      .map((u) => {
        if (this.state.userStats[u.uid] === undefined) {
          return { valid: false }
        } else {
          const ustat = this.state.userStats[u.uid][this.state.month] || {
            created: 0,
            smsparts: 0,
            acknowledged: 0,
            deleted: 0,
            changed: 0,
          }
          return {
            practiceid: u.practiceid,
            short: u.short,
            created: ustat.created,
            acknowledged: ustat.acknowledged,
            smsparts: ustat.smsparts,
            deleted: ustat.deleted,
            changed: ustat.changed,
            valid: true,
          }
        }
      })
      .filter((u) => u.valid === true)
    if (this.state.apiAccount === '') {
      const extraList = Object.keys(this.state.userStats)
        .map((u) => {
          if (this.shorts.findIndex((s) => s.uid === u) === -1) {
            const ustat = this.state.userStats[u][this.state.month]
            if (ustat === undefined) {
              return { valid: false }
            } else {
              return {
                practiceid: u,
                short: 'Unknown',
                created: ustat.created,
                acknowledged: ustat.acknowledged,
                smsparts: ustat.smsparts,
                deleted: ustat.deleted,
                changed: ustat.changed,
                valid:
                  ustat.created +
                    ustat.acknowledged +
                    ustat.smsparts +
                    ustat.deleted +
                    ustat.changed >
                  0,
              }
            }
          } else {
            return { valid: false }
          }
        })
        .filter((u) => u.valid === true)
      reducedList.push(...extraList)
      return reducedList
    } else {
      return reducedList
    }
  }

  invoiceRender() {
    if (this.state.userListFiltered.length === 0 || this.state.month === '') {
      return null
    } else {
      const reducedList = this.getReducedList()

      if (reducedList.length === 0) {
        return <></>
      }

      const totalVal = reducedList.reduce((a, b) => {
        return {
          created: a.created + b.created,
          smsparts: a.smsparts + b.smsparts,
          acknowledged: a.acknowledged + b.acknowledged,
          deleted: a.deleted + b.deleted,
          changed: a.changed + b.changed,
        }
      })
      return (
        <>
          {reducedList.sort(this.state.sortInvoiceFun).map((u, ui) => (
            <Fragment key={`monthly${ui}`}>
              <div className="gcs1 gce2 ph2 pv1">{u.practiceid}</div>
              <div className="gcs2 gce3 ph2 pv1">{u.short}</div>
              <div className="gcs3 gce4 ph2 pv1 tr">{u.created}</div>
              <div className="gcs4 gce5 ph2 pv1 tr">{u.deleted}</div>
              <div className="gcs5 gce6 ph2 pv1 tr">{u.changed}</div>
              <div className="gcs6 gce7 ph2 pv1 tr">{u.acknowledged}</div>
              <div className="gcs7 gce8 ph2 pv1 tr">{u.smsparts}</div>
            </Fragment>
          ))}
          <Fragment key={`total`}>
            <div className="gcs1 gce2 pa2 bg-medmeblue-verylight"></div>
            <div className="gcs2 gce3 pa2 bg-medmeblue-verylight b">Total</div>
            <div className="gcs3 gce4 pa2 bg-medmeblue-verylight b tr">
              {totalVal.created}
            </div>
            <div className="gcs4 gce5 pa2 bg-medmeblue-verylight b tr">
              {totalVal.deleted}
            </div>
            <div className="gcs5 gce6 pa2 bg-medmeblue-verylight b tr">
              {totalVal.changed}
            </div>
            <div className="gcs6 gce7 pa2 bg-medmeblue-verylight b tr">
              {totalVal.acknowledged}
            </div>
            <div className="gcs7 gce8 pa2 bg-medmeblue-verylight b tr">
              {totalVal.smsparts}
            </div>
          </Fragment>
        </>
      )
    }
  }

  createCSV(excludeEmpty) {
    const reducedList = this.getReducedList()

    const minval = excludeEmpty ? 0 : -1
    const outList = reducedList
      .filter(
        (a) =>
          a.created + a.acknowledged + a.deleted + a.changed + a.smsparts >
          minval
      )
      .sort(this.state.sortInvoiceFun)
    const totalVal = reducedList.reduce((a, b) => {
      return {
        created: a.created + b.created,
        smsparts: a.smsparts + b.smsparts,
        acknowledged: a.acknowledged + b.acknowledged,
        deleted: a.deleted + b.deleted,
        changed: a.changed + b.changed,
      }
    })
    let csvString =
      'PracticeId,Name,Appointments,Cancellations,Changes,Confirmations,SMS'
    for (let index = 0; index < outList.length; index++) {
      const u = outList[index]
      csvString += `\r\n${u.practiceid},${u.short},${u.created},${u.deleted},${u.changed},${u.acknowledged},${u.smsparts}`
      if (index === outList.length - 1) {
        csvString += `\r\nTotal,Total,${totalVal.created},${totalVal.deleted},${totalVal.changed},${totalVal.acknowledged},${totalVal.smsparts}`
        csvString = 'data:text/csv;charset=utf-8,' + csvString
        const link = document.createElement('a')
        link.setAttribute('href', encodeURI(csvString))
        link.setAttribute('download', `Medme_${this.state.month}.csv`)
        link.click()
      }
    }
  }

  render() {
    return (
      <>
        <div ref={this.fullRef} className="w-100 h-100 pa3">
          <div ref={this.buttonBoxRef} className="pb2 grid stats-top-box">
            {this.state.mode === 'stats' ? (
              <div className="gcs1 gce2 grs1 gre2">
                <button
                  className="bg-medmeblue-light bn grow br3 ph3 pv2 mr2 fw2 mid-gray"
                  onClick={this.selectAll}
                >
                  Select All
                </button>
                {this.props.usersFiltered.length === 0 ? null : (
                  <button
                    className="bg-medmeblue-light bn grow br3 ph3 pv2 mr2 fw2 mid-gray"
                    onClick={this.adminFilter}
                  >
                    Apply Admin Filter
                  </button>
                )}
                <select value={this.state.show} onChange={this.handleChange}>
                  <option value="All">All</option>
                  {this.state.users.length === 0
                    ? null
                    : this.state.users.map((oneUser, idx) => {
                        return (
                          <option key={idx} value={oneUser}>
                            {this.findShort(oneUser)}
                          </option>
                        )
                      })}
                </select>
                {this.state.victoryData === null ? null : (
                  <>
                    <div className="di medmeblue-dark ph3">
                      &#9200;{' '}
                      {this.state.victoryData['Appointments']
                        .map((a) => a.y)
                        .reduce((a, b) => a + b, 0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </div>
                    <div className="di medmegreen-dark ph3">
                      &#10004;{' '}
                      {this.state.victoryData['Confirmed']
                        .map((a) => a.y)
                        .reduce((a, b) => a + b, 0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </div>
                    <div className="di medmered-dark ph3">
                      &#128206;{' '}
                      {this.state.victoryData['Forms']
                        .map((a) => a.y)
                        .reduce((a, b) => a + b, 0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </div>
                    <div className="di gray ph3">
                      SMS's{' '}
                      {this.state.victoryData['SMSParts']
                        .map((a) => a.y)
                        .reduce((a, b) => a + b, 0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className="gcs1 gce2 grs1 gre2">
                Month:{' '}
                <select
                  value={this.state.month}
                  onChange={(e) => {
                    if (verbose) console.log(e.target.value)
                    this.setState({ month: e.target.value })
                  }}
                >
                  {this.state.months.length === 0
                    ? null
                    : this.state.months.sort(sortdesc).map((oneMonth, idx) => {
                        return (
                          <option key={`monthopt${idx}`} value={oneMonth}>
                            {oneMonth}
                          </option>
                        )
                      })}
                </select>{' '}
                Account:{' '}
                <select
                  value={this.state.apiAccount}
                  onChange={(e) => {
                    if (verbose) console.log(e.target.value)
                    this.setState({ apiAccount: e.target.value })
                  }}
                >
                  {this.apiUsers.length === 0
                    ? null
                    : this.apiUsers
                        .filter(
                          (a) =>
                            a.uid === this.props.authUser.uid ||
                            this.props.authUser.uid ===
                              'gKnjQPXp4pf0920MDWT7I5vMGt63'
                        )
                        .map((oneUser, idx) => {
                          return (
                            <option key={`useropt${idx}`} value={oneUser.uid}>
                              {oneUser.name}
                            </option>
                          )
                        })}
                </select>
                <button
                  className="bg-medmeblue-light bn grow br3 ph3 pv2 ml3 fw2 mid-gray"
                  onClick={(e) => {
                    this.createCSV(true)
                  }}
                >
                  Export CSV (excl empty)
                </button>
                <button
                  className="bg-medmeblue-light bn grow br3 ph3 pv2 ml3 fw2 mid-gray"
                  onClick={(e) => {
                    this.createCSV(false)
                  }}
                >
                  Export CSV (all)
                </button>
              </div>
            )}
            <div className="gcs2 gce3 grs1 gre2">
              <button
                className="bg-medmeblue-light bn grow br3 ph3 pv2 mr2 fw2 mid-gray"
                onClick={(e) => {
                  if (modes[this.state.mode].next === 'monthly') {
                    if (
                      this.props.authUser.uid !== 'gKnjQPXp4pf0920MDWT7I5vMGt63'
                    ) {
                      setTimeout(() => {
                        this.setState({ apiAccount: this.props.authUser.uid })
                      }, 500)
                    }
                  }
                  this.setState({ mode: modes[this.state.mode].next })
                }}
              >
                Mode: {modes[this.state.mode].label}
              </button>
            </div>
          </div>
          {this.state.victoryData === null || this.state.mode !== 'stats' ? (
            <div
              height={this.state.graphHeight - 80}
              width={this.state.graphWidth}
            >
              <div className="grid w-100 h-100 pb4">
                <div
                  className="gcs1 gce2 pa2 bg-medmegreen-verylight pointer"
                  onClick={(e) => {
                    this.setState({ sortInvoiceFun: sortInvoicePracticeId })
                  }}
                >
                  PracticeId
                </div>
                <div
                  className="gcs2 gce3 pa2 bg-medmegreen-verylight pointer"
                  onClick={(e) => {
                    this.setState({ sortInvoiceFun: sortInvoiceShort })
                  }}
                >
                  Name
                </div>
                <div
                  className="gcs3 gce4 pa2 bg-medmegreen-verylight pointer"
                  onClick={(e) => {
                    this.setState({ sortInvoiceFun: sortInvoice })
                  }}
                >
                  Appts
                </div>
                <div className="gcs4 gce5 pa2 bg-medmegreen-verylight">
                  Cancels
                </div>
                <div className="gcs5 gce6 pa2 bg-medmegreen-verylight">
                  Changes
                </div>
                <div className="gcs6 gce7 pa2 bg-medmegreen-verylight">
                  Confirms
                </div>
                <div className="gcs7 gce8 pa2 bg-medmegreen-verylight">
                  SMSs
                </div>
                {this.invoiceRender()}
              </div>
            </div>
          ) : (
            <div>
              <VictoryChart
                height={this.state.graphHeight - 80}
                width={this.state.graphWidth}
              >
                <VictoryLine
                  height={this.state.graphHeight - 80}
                  interpolation="natural"
                  animate={{
                    duration: this.victoryAnimate,
                    // onLoad: { duration: this.victoryOnLoad }
                  }}
                  style={{
                    data: { stroke: '#005ad4' },
                    parent: { border: '1px solid #ccc' },
                  }}
                  data={this.state.victoryData['Appointments']}
                  // labelComponent={<VictoryTooltip/>}
                />
                <VictoryLine
                  interpolation="natural"
                  animate={{
                    duration: this.victoryAnimate,
                    // onLoad: { duration: this.victoryOnLoad },
                    delay: this.victoryAnimate - 1000,
                  }}
                  style={{
                    data: { stroke: '#009557' },
                    parent: { border: '1px solid #ccc' },
                  }}
                  data={this.state.victoryData['Confirmed']}
                  // labelComponent={<VictoryTooltip/>}
                />
                <VictoryLine
                  interpolation="natural"
                  animate={{
                    duration: this.victoryAnimate,
                    // onLoad: { duration: this.victoryOnLoad },
                    delay: (this.victoryAnimate - 1000) * 2,
                  }}
                  style={{
                    data: { stroke: '#ccc' },
                    parent: { border: '1px solid #ccc' },
                  }}
                  data={this.state.victoryData['Forms']}
                />
                <VictoryLine
                  interpolation="natural"
                  animate={{
                    duration: this.victoryAnimate,
                    // onLoad: { duration: this.victoryOnLoad },
                    delay: (this.victoryAnimate - 1000) * 2,
                  }}
                  style={{
                    data: { stroke: '#950057' },
                    parent: { border: '1px solid #ccc' },
                  }}
                  data={this.state.victoryData['SMSParts']}
                />
                <VictoryAxis
                  height={this.state.graphHeight}
                  crossAxis
                  tickCount={14}
                  tickLabelComponent={
                    <VictoryLabel
                      textAnchor="start"
                      dx={-40}
                      dy={-8}
                      angle={-90}
                    />
                  }
                />
                <VictoryAxis
                  crossAxis
                  dependentAxis={true}
                  tickCount={8}
                  height={this.state.graphHeight}
                />
              </VictoryChart>
              <div className="w-100 grid stats-grid mt4 pl3">
                <div className="gcs1 gce2 fw6">Month</div>
                <div className="gcs2 gce3 fw6">Appointments</div>
                <div className="gcs3 gce4 fw6">Confirmations</div>
                <div className="gcs4 gce5 fw6">Forms</div>
                <div className="gcs5 gce6 fw6">SMS's</div>
                {this.state.victoryData['Appointments'].map((mth, ix) => {
                  return (
                    <>
                      <div key={ix} className="gcs1 gce2 mt2 fw6">
                        {mth.x}
                      </div>
                      <div className="gcs2 gce3 mt2">
                        {this.state.victoryData['Appointments'][ix].y}
                      </div>
                      <div className="gcs3 gce4 mt2">
                        {this.state.victoryData['Confirmed'][ix].y}
                      </div>
                      <div className="gcs4 gce5 mt2">
                        {this.state.victoryData['Forms'][ix].y}
                      </div>
                      <div className="gcs5 gce6 mt2">
                        {this.state.victoryData['SMSParts'][ix].y}
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          )}
        </div>
        <Toasty message={this.state.toast} />
      </>
    )
  }
}

export default withFirebase(Stats)
